<template>
  <div class="dash-left-side dash-list-side" ref="dashboardList">
    <div class="dash-create">
      <v-img :src="dash_create" alt="create" @click="createDash"></v-img>
    </div>
    <v-divider></v-divider>
    <div class="tab-div dash-access-toggle justify-space-between">
      <v-btn-toggle
        v-model="isPublic"
        class="axis-toggle"
        mandatory
        @update:modelValue="handleAccessToggleChange"
      >
        <v-btn :value="false" class="switch-btn" outlined>My Dashboards</v-btn>
        <v-btn :value="true" class="switch-btn" outlined>Shared</v-btn>
      </v-btn-toggle>
    </div>
    <DashChartListingLoader v-if="showLoader == true" />
    <div class="dash-list-public" v-else>
      <!-- <h6>{{ isPublic ? 'Public' : 'Private' }}</h6> -->
      <div
        v-for="(dashboard, index) in filteredDashboards"
        :key="index"
        class="dash-list-items"
        :class="{ active: selectedDashboardId === dashboard.ID }"
        @click="changeDashboard(dashboard)"
      >
        <div>
          <img class="set-cursor" :src="dashboard.isDefault ? filledStar : star_empty" alt="star" v-if="!isPublic" @click="toggleDefaultDashboard(dashboard.ID, dashboard.name)" />
          <span class="dash-name">{{ dashboard.name }}</span>
          <img :src="GlobeSimple" alt="globe" v-if="!isPublic && dashboard.AccessLevel === 'public'" class="globe-icon" />

        </div>
      </div>
    </div>
    <!-- <v-divider></v-divider> -->
    <!-- <div class="dash-list-public">
            <h6>Public</h6>
            <div 
            v-for="(dashboard, index) in publicDashboards" 
            :key="index"
            class="dash-list-items"
            @click="changeDashboard(dashboard)"
            >
                <img :src="star_empty" alt="star" />
                <span class="dash-name">{{ dashboard.name }}</span>
                <img :src="star_empty" alt="star" />
            </div>
        </div> -->
  </div>
</template>

<script>
import api from "../../../axiosInterceptor";
import DashChartListingLoader from "../Loaders/DashChartListingLoader.vue";
import { mapGetters } from "vuex";
export default {
  name: "DashboardList",
  components: {
    DashChartListingLoader,
  },
  data() {
    return {
      dash_create: require("../../../assets/Svg/Dashboard/create-btn.svg"),
      star_empty: require("../../../assets/Svg/Dashboard/star-empty.svg"),
      filledStar: require("../../../assets/Svg/Dashboard/small-filled-star.svg"),
      GlobeSimple: require("../../../assets/Svg/GlobeSimple.svg"), // Added GlobeSimple SVG
      dashboardName: [],
      dashboardsArr: [],
      // duplicate: require("../../../assets/Svg/Dashboard/duplicate.svg"),
      accessLevel: "private",
      isPublic: false,
      showLoader: true,
      selectedDashboardId: null,
    };
  },
  computed: {
    ...mapGetters("tenantId", ["getUser"]),
    filteredDashboards() {
      const userEmail = this.getUser?.username;
      return this.dashboardsArr.filter((dashboard) =>
        this.isPublic
          ? dashboard.AccessLevel === "public"
          : dashboard.AccessLevel === "private" ||
            dashboard.AccessLevel === "personal" ||
            (dashboard.AccessLevel === "public" && dashboard.createdBy === userEmail)
      );
    },
  },
  methods: {
    handleAccessToggleChange() {
      // Update access level based on the switch state
      this.accessLevel = this.isPublic ? "public" : "private";
    },
    // changeDashboard(dashboard) {
    //   this.$emit("selected-dashboard", dashboard);
    // },
    changeDashboard(dashboard) {
      this.selectedDashboardId = dashboard.ID; // Set selected dashboard ID
      this.$emit("selected-dashboard", dashboard);
    },
    createDash() {
      this.$router.push("/Dashboards/create");
    },
    getSavedDashboards() {
      this.showLoader = true;
      this.timeoutMessage = "";
      api
        .get("public/api/v1/dashboards")
        .then((response) => {
          if (
            response &&
            response.data &&
            (response.data.statusCode === 200 ||
              response.data.statusCode === 201)
          ) {
            this.dashboardsArr = response.data.data.dashboards;
            this.dashboardName = response.data.data.dashboards?.map(
              (dashboard) => dashboard.name
            );
            // Check if any dashboard is marked as default and set it to localStorage
            const defaultDashboard = this.dashboardsArr.find(dashboard => dashboard.isDefault);
            if (defaultDashboard) {
              localStorage.setItem("defaultDashboard", defaultDashboard.name);
            }
          } else if (response && response.data && response.data.message) {
            this.timeoutMessage = response.data.message;
          } else {
            this.timeoutMessage = "Error in fetching Dashboards";
          }
        })
        .catch((error) => {
          this.timeoutMessage = error;
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    toggleDefaultDashboard(dashboardId, dashboardName) {
      // Find the current dashboard and toggle its isDefault property
      const currentDashboard = this.dashboardsArr.find(dashboard => dashboard.ID === dashboardId);
      if (currentDashboard) {
        // If the dashboard is already default, remove its default status
        if (currentDashboard.isDefault) {
          currentDashboard.isDefault = false;
        } else {
          // Disable the previous default dashboard
          this.dashboardsArr.forEach(dashboard => {
            if (dashboard.ID !== dashboardId && dashboard.isDefault) {
              dashboard.isDefault = false;
            }
          });
          // Set the current dashboard as default
          currentDashboard.isDefault = true;
        }
        // Update the dashboard in the backend
        const requestBody = {
          Data: {
            dashboard: {
              name: dashboardName,
              isDefault: currentDashboard.isDefault
            }
          }
        };
        api
          .put(
            process.env.VUE_APP_API_URL + "public/api/v1/dashboards/" + dashboardId,
            requestBody,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                "X-TenantID": this.getTenantId,
              },
            }
          )
          .then(response => {
            if (response && response.data && response.data.statusCode === 200) {
              console.log("Dashboard default status updated successfully.");
            } else {
              console.error("Failed to update dashboard default status.");
            }
          })
          .catch(error => {
            console.error("Error updating dashboard default status:", error);
          });
      }
    },
  },
  mounted() {
    this.getSavedDashboards();
  },
};
</script>

<style scoped>
.dash-list-items.active {
  background: #f0f0f0;
  font-weight: bold;
}
.globe-icon {
  position: absolute; 
  right: 15px;
}

</style>