<template>
  <div class="filter-wrapper studio-new">
    <v-form>
      <div class="studio-top-heading pb-2">
        <div class="d-flex align-center justify-space-between w-100">
        <h6>Plot</h6>
        <v-btn
            v-if="selectedSavedChartName != null"
            variant
            class="save-btn studio-btn text-none"
            @click="resetChartData"
          >
            <img :src="addDashboard" alt="add" width="12px" height="12px" />
            <h6>Create</h6>
          </v-btn>
        </div>
        <!-- <div class="studio-top-right">
          <div
            v-if="chartType === 'Line Plot' || chartType === 'Scatter Plot'"
            class="switch-div justify-space-between w-100"
          >
            <label for="">X Axis Scale:</label>
            <v-switch
              v-model="isXLog"
              color="info"
              inset
              :label="xAxisScale"
              @change="handleXScaleToggleChange"
              hide-details
            ></v-switch>
          </div>
          <div
            v-if="chartType === 'Line Plot' || chartType === 'Scatter Plot'"
            class="switch-div justify-space-between w-100"
          >
            <label for="">Y Axis Scale:</label>
            <v-switch
              v-model="isYLog"
              color="info"
              inset
              :label="yAxisScale"
              @change="handleYScaleToggleChange"
              hide-details
            ></v-switch>
          </div>
          <div class="switch-icon-div">
            <div v-if="isAdmin" class="switch-div justify-space-between w-100">
              <label for="">Access Level:</label>
              <v-switch
                v-model="isPublic"
                color="info"
                inset
                :label="accessLevel"
                @change="handleToggleChange"
                hide-details
              ></v-switch>
            </div>
          </div>
        </div> -->
      </div>
      <v-row no-gutters>
        <v-col cols="12" class="studio-input-form" v-if="isFluidsDataStudio || isDashboard">
          
          <v-row no-gutters style=" width: 100%;">
            <v-col cols="12" class="pa-0 pb-4" v-if="shouldRenderChartList">
              <label>Switch Plot</label>
              <v-select variant="solo" class="custom-field" v-model="selectedSavedChartName"
                :items="filteredChartsNames" placeholder="Switch Plot" @update:modelValue="savedChartChange"
                hide-details>
                <!-- Search Input + loader -->
                <template #prepend-item>
                  <v-text-field variant="solo" class="custom-field mx-4" v-model="chartSearchQuery" placeholder="Switch Plot" dense outlined clearable
                    @input="filterChartList" @click:clear="resetFilterChartList" hide-details></v-text-field>
                  <div class="loader-container" v-if="loadingSavedCharts">
                    <v-progress-circular indeterminate color="black" class="loaders"></v-progress-circular>
                  </div>
                </template>
                <template #no-data>
                  <v-list-item v-if="!loadingSavedCharts">
                    <v-list-item-content>No data available</v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>

            <v-col class="d-flex align-end" cols="12">
              <div class="studio-btns" v-if="showInputs">
                <v-btn
                    v-if="
                      selectedSavedChartName != null &&
                      (accessLevel == 'private' ||
                        (accessLevel == 'public' && isAdmin))
                    "
                    flat
                    :disabled="!isFormValid"
                    class="delete-btn text-none"
                    @click="showDeleteConfirmation(this.selectedChartId)"
                  >
                    <v-img
                      src="../../../assets/Svg/delete.svg"
                      alt="delete"
                      width="15"
                      height="15"
                      class="mr-1"
                    ></v-img>
                  </v-btn>
                  <div
                    :style="{
                      width: selectedSavedChartName != null && (accessLevel == 'private' || (accessLevel == 'public' && isAdmin)) 
                        ? 'calc(100% - 28px)' 
                        : '100%',
                    }"
                  >
                  <v-btn v-if="selectedSavedChartName == null && createNewChart == false" flat :disabled="!isFormValid"
                    :loading="createLoading" class="save-btn text-none" @click="createChart">
                    <div class="save-btn-img">
                      <v-img src="../../../assets/Svg/save.svg" alt="save"></v-img>
                    </div>
                    <span>Save</span>
                  </v-btn>
                  <v-btn
                    v-if="selectedSavedChartName != null && (accessLevel == 'private' || (accessLevel == 'public' && isAdmin))"
                    flat :disabled="!isUpdateEnabled" :loading="updateLoading" class="save-btn text-none update-btn"
                    @click="showUpdateConfirmation(this.selectedChartId)">
                    <div class="save-btn-img ml-2">
                      <v-img src="../../../assets/Svg/save.svg" alt="update"></v-img>
                    </div>
                    <span>Update</span>
                  </v-btn>
                </div>
              </div>
            </v-col>
            <!-- <v-col cols="12" lg="6" class="pa-0 mt-lg-0"
              :class="{ 'mt-4': selectedSavedChartName != null && (accessLevel == 'private' || (accessLevel == 'public' && isAdmin)), 'mt-0': !(selectedSavedChartName != null && (accessLevel == 'private' || (accessLevel == 'public' && isAdmin))) }">
              <div class="saved-chart-right-btns d-flex align-center justify-end">
                <v-btn v-if="selectedSavedChartName != null" variant class="save-btn studio-btn text-none"
                  @click="resetChartData">
                  <img :src="addDashboard" alt="add" />
                  <h6>Create Plot</h6>
                </v-btn>
                <v-btn
                  v-if="selectedSavedChartName != null && (accessLevel == 'private' || (accessLevel == 'public' && isAdmin))"
                  flat :disabled="!isFormValid" class="delete-btn ml-2 text-none" @click="showDeleteConfirmation(this.selectedChartId)">
                  <v-img src="../../../assets/Svg/Chart/delete-white.svg" alt="delete" width="15" height="15"
                    class="mr-1"></v-img>
                  <span>Delete</span>
                </v-btn>
              </div>
            </v-col> -->
          </v-row>
        </v-col>
        <!-- <v-col cols="12">
          <div class="chart-opts mt-4" :class="{ 'mb-0': !showInputs, 'mb-1': showInputs }">
            <h6 class="ma-0">Plot Options</h6>
            <div v-if="chartType === 'Line Plot' || chartType === 'Scatter Plot'" class="switch-div">
                <label for="">X Axis Scale:</label>
                <v-switch v-model="isXLog" color="info" inset :label="xAxisScale" @change="handleXScaleToggleChange"
                  hide-details></v-switch>
              </div>
                      <div v-if="chartType === 'Line Plot' || chartType === 'Scatter Plot'" class="switch-div">
                <label for="">Y Axis Scale:</label>
                <v-switch v-model="isYLog" color="info" inset :label="yAxisScale" @change="handleYScaleToggleChange"
                  hide-details></v-switch>
              </div>
            <div class="switch-icon-div">
              <div v-if="isAdmin" class="switch-div">
                <label for="">Access Level:</label>
                <v-switch v-model="isPublic" color="info" inset :label="accessLevel" @change="handleToggleChange"
                  hide-details></v-switch>
              </div>
              <img @click="toggleInputs" v-if="isFluidsDataStudio && !showInputs" :src="icon" alt="chevron-down">
              <img @click="toggleInputs" v-if="isFluidsDataStudio && showInputs" :src="icon2" alt="chevron-up">
            </div>
          </div>
        </v-col> -->
        <div  v-if="isAdmin" class="tab-icon-div justify-space-between w-100 pt-4">
          <v-btn-toggle
            v-model="isPublic"
            class="axis-toggle top-axis"
            mandatory
            @update:modelValue="handleToggleChange"
          >
          <v-btn :value="false" class="switch-btn" outlined>Private</v-btn>
            <v-btn :value="true" class="switch-btn" outlined>Public</v-btn>
          </v-btn-toggle>
        </div>

        <div class="chart-opts-input" v-if="showInputs">
          <v-row no-gutters style="row-gap: 8px;">
            <v-col cols="12" class="pa-0">
              <label for="">Plot Title</label>
              <v-text-field variant="solo" class="custom-field studio-title" v-model="chartTitle" required
                placeholder="Plot Title" hide-details outlined @input="updateChartTitle"></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-0">
              <label for="">Test Type</label>
              <v-select variant="solo" class="custom-field" v-model="selectedTableTypeName"
                :items="filteredTableTypesNames" placeholder="Test Type" @update:modelValue="tableTypeChange"
                hide-details>
                <!-- Search Input + loader -->
                <template #prepend-item>
                  <v-text-field variant="solo" class="custom-field mx-4" v-model="tableTypeSearchQuery"
                    placeholder="Test Type" dense outlined clearable @input="filterTableTypeList"
                    @click:clear="resetTableTypeList" hide-details></v-text-field>
                  <div class="loader-container" v-if="loadingTableType">
                    <v-progress-circular indeterminate color="black" class="loaders"></v-progress-circular>
                  </div>
                </template>
                <template #no-data>
                  <v-list-item v-if="!loadingTableType">
                    <v-list-item-content>No data available</v-list-item-content>
                  </v-list-item>
                </template></v-select>
            </v-col>
            <v-col cols="12" class="pa-0">
              <label for="">Plot Type</label>
              <v-select variant="solo" class="custom-field" v-model="selectedChartTypeName"
                :items="filteredChartTypesNames" placeholder="Plot Type" @update:modelValue="chartTypeChange"
                :search="searchQuery" :disabled="selectedTableTypeName === null" hide-details>
                <!-- Search Input + loader -->
                <template #prepend-item>
                  <v-text-field variant="solo" class="custom-field mx-4" v-model="chartTypeSearchQuery"
                    placeholder="Plot Type" dense outlined clearable @input="filterChartTypeList"
                    @click:clear="resetChartTypeList" hide-details></v-text-field>
                  <div class="loader-container" v-if="loadingChartType">
                    <v-progress-circular indeterminate color="black" class="loaders"></v-progress-circular>
                  </div>
                </template>
                <template #no-data>
                  <v-list-item v-if="!loadingChartType">
                    <v-list-item-content>No data available</v-list-item-content>
                  </v-list-item>
                </template></v-select>
            </v-col>
            <v-col cols="12" class="px-0 pt-2 pb-0" v-if="showXAxis">
              <div class="d-flex align-center justify-space-between">
                <label for="">X-Axis Column</label>
                <div
                  v-if="chartType === 'Line Plot' || chartType === 'Scatter Plot'"
                  class="tab-div justify-space-between"
                  >
                  <v-btn-toggle
                    v-model="isXLog"
                    class="axis-toggle"
                    mandatory
                    @update:modelValue="handleXScaleToggleChange"
                  >
                  <v-btn :value="true" class="switch-btn" outlined>Log</v-btn>
                    <v-btn :value="false" class="switch-btn" outlined>Linear</v-btn>
                  </v-btn-toggle>
                </div>
            </div>
              <v-select variant="solo" class="custom-field" v-model="selectedXAxisKey" :items="filteredXAxisItems"
                item-title="displayName" item-value="name" placeholder="X-Axis Column"
                @update:modelValue="handleXSelection" hide-details>
                <!-- Search Input + loader -->
                <template #prepend-item>
                  <v-text-field variant="solo" class="custom-field mx-4" v-model="xAxisSearchQuery"
                    placeholder="X-Axis Column" dense outlined clearable @input="filterXAxisList"
                    @click:clear="resetXAxisList"></v-text-field>
                  <div class="loader-container" v-if="loadingXAxisData">
                    <v-progress-circular indeterminate color="black" class="loaders"></v-progress-circular>
                  </div>
                </template>
                <template #no-data>
                  <v-list-item v-if="!loadingXAxisData">
                    <v-list-item-content>No data available</v-list-item-content>
                  </v-list-item>
                </template></v-select>
            </v-col>
            <v-col cols="12" class="px-0 py-2" v-if="showYAxis">
              <div class="d-flex align-center justify-space-between">
                <label for="">Y-Axis Column(s)</label>
                <div
                  v-if="chartType === 'Line Plot' || chartType === 'Scatter Plot'"
                  class="tab-div justify-space-between"
                >
                <v-btn-toggle
                    v-model="isYLog"
                    class="axis-toggle"
                    mandatory
                    @update:modelValue="handleYScaleToggleChange"
                  >
                  <v-btn class="switch-btn" :value="true" outlined>Log</v-btn>
                    <v-btn class="switch-btn" :value="false" outlined>Linear</v-btn>
                  </v-btn-toggle>
                </div>
              </div>
              <v-select variant="solo" v-model="selectedYAxisKey" :items="filteredYAxisItems" multiple chips clearable
                closable-chips placeholder="Y-Axis Column(s)" item-title="displayName" item-value="name"
                :return-object="false" @update:modelValue="handleYSelection"
                class="custom-y-axis-chips custom-field" hide-details>
                <!-- Search Input + loader -->
                <template #prepend-item>
                  <v-text-field variant="solo" class="custom-field mx-4" v-model="yAxisSearchQuery"
                    placeholder="Y-Axis Column(s)" dense outlined clearable @input="filterYAxisList"
                    @click:clear="resetYAxisList"></v-text-field>
                  <div class="loader-container" v-if="loadingYAxisData">
                    <v-progress-circular indeterminate color="black" class="loaders"></v-progress-circular>
                  </div>
                </template>
                <template #no-data>
                  <v-list-item v-if="!loadingYAxisData">
                    <v-list-item-content>No data available</v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" class="pa-0" v-if="showZAxis">
              <label for="">Z-Axis Column</label>
              <v-select variant="solo" class="custom-field" v-model="selectedZAxisKey"
                placeholder="Z-Axis Column" :items="filteredZAxisItems" item-title="displayName"
                item-value="name" @update:modelValue="handleZSelection" hide-details>
                <!-- Search Input + loader -->
                <template #prepend-item>
                  <v-text-field variant="solo" class="custom-field mx-4" v-model="zAxisSearchQuery" placeholder="Z-Axis Column" dense outlined
                    clearable @input="filterZAxisList" @click:clear="resetZAxisList"></v-text-field>
                  <div class="loader-container" v-if="loadingZAxisData">
                    <v-progress-circular indeterminate color="black" class="loaders"></v-progress-circular>
                  </div>
                </template>
                <template #no-data>
                  <v-list-item v-if="!loadingZAxisData">
                    <v-list-item-content>No data available</v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" class="pa-0" v-if="showColorGroup">
              <label for="">Legend</label>
              <v-select variant="solo" class="custom-field" v-model="selectedColorGroupKey" clearable
                placeholder="Legend" :items="filteredColorGroupItems" item-title="displayName" item-value="name"
                @update:modelValue="handleColorGroupSelection" hide-details>
                <!-- Search Input + loader -->
                <template #prepend-item>
                  <v-text-field v-model="colorGroupSearchQuery" placeholder="Legend" dense outlined clearable
                    @input="filterColorGroupList" @click:clear="resetColorGroupList" hide-details></v-text-field>
                  <div class="loader-container" v-if="loadingZAxisData">
                    <v-progress-circular indeterminate color="black" class="loaders"></v-progress-circular>
                  </div>
                </template>
                <template #no-data>
                  <v-list-item v-if="!loadingZAxisData">
                    <v-list-item-content>No data available</v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col v-if="showXBinsInput" cols="12" class="pa-0">
              <label for="">X bins</label>
              <div id="rangeValue">{{ xBinsAsNumber }}</div>
              <input type="range" id="rangeInput" class="slider" min="2" max="100" step="1" v-model="xBins"
                @input="updateXBins" />
            </v-col>
            <v-col v-if="showYBinsInput" cols="12" class="pa-0"
              :class="{ 'pl-0': showZAxis || showColorGroup }">
              <label for="">Y bins</label>
              <div id="rangeValue">{{ yBinsAsNumber }}</div>
              <input type="range" id="rangeInput" class="slider" min="2" max="100" step="1" v-model="yBins"
                @input="updateYBins" />
            </v-col>
            <v-col cols="12" class="pa-0 pl-lg-4 one"
              v-if="!showZAxis && !showColorGroup && !showXBinsInput && !showYBinsInput">
            </v-col>
            <v-col cols="12" class="pa-0 pl-lg-4 one"
              v-if="!showZAxis && !showColorGroup && !showXBinsInput && !showYBinsInput">
            </v-col>
            <v-col cols="12" class="pa-0 pl-lg-4 two"
              v-if="showColorGroup && !showZAxis && (!showXBinsInput || !showYBinsInput)">
            </v-col>
            <v-col cols="12" class="pa-0 pl-lg-4 four"
              v-if="showZAxis && showColorGroup && showXBinsInput && showYBinsInput">
            </v-col>
            
          </v-row>
        </div>
        <v-col v-if="$route.name === 'DataInventoryAnalysis'" cols="12" class="pa-0">
          <label for="">Select Saved Dashboard</label>
          <v-select v-model="dashboard" placeholder="Select Saved Dashboard" :items="dashboardItems"
            @update:modelValue="handleDshboardSelection"></v-select>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout">
        {{ snackbar.message }}
      </v-snackbar>
    </v-form>
    <v-dialog transition="dialog-top-transition" v-model="confirmDeleteDialog" width="417">
      <div class="cross-btn-div" @click="closeDeleteConfirmation">
        <v-img :src="cross" alt="close"></v-img>
      </div>
      <v-alert title="Delete Plot" :text=confirmDeleteText>
        <div class="alert-btns-div">
          <v-btn class="cancel-btn" flat @click="closeDeleteConfirmation">Cancel</v-btn>
          <v-btn class="delete-btn" flat @click="deleteChart" :loading="deleteLoading">
            <v-img :src="delete_icon" alt="delete-icon" width="16" height="15" class="mr-2"></v-img>
            Delete Plot</v-btn>
        </div>
      </v-alert>
    </v-dialog>
    <v-dialog transition="dialog-top-transition" v-model="confirmUpdateDialog" width="417">
      <div class="cross-btn-div" @click="closeUpdateConfirmation">
        <v-img :src="cross" alt="close"></v-img>
      </div>
      <v-alert title="Update Plot" :text=confirmUpdateText>
        <div class="alert-btns-div">
          <v-btn class="cancel-btn" flat @click="closeUpdateConfirmation">Cancel</v-btn>
          <v-btn
                    v-if="selectedSavedChartName != null && (accessLevel == 'private' || (accessLevel == 'public' && isAdmin))"
                    flat :disabled="!isUpdateEnabled" :loading="updateLoading" class="save-btn text-none update-btn"
                    @click="updateChart">
                    <div class="save-btn-img ml-2">
                      <v-img src="../../../assets/Images/save btn.png" alt="update"></v-img>
                    </div>
                    <span>Update</span>
                  </v-btn>
        </div>
      </v-alert>
    </v-dialog>
    <StudioModal ref="childComponent" @open-dialog="openDialog" />
    <Filters ref="filters" v-if="isFluidsDataStudio" @drawerStateChanged="handleRightDrawerStateChange"
      :callDefaultFilter="callDefaultFilter" :selectedType="selectedTableTypeName" :filterFromChart="filterFromChart"
      @filter-data="getTableDataAgainstFilter" @reset-saved-filter="handleResetSavedFilter"
      @reset-call-default-filter="resetCallDefaultFilter" @update-chart="callUpdateChartMethod" @drawerValue="toggleInputs" />
  </div>
</template>

<script>
import api from "../../../axiosInterceptor";
// import TabularLoaders from "../Loaders/TabularLoaders.vue";
import Filters from "../Common/Filters.vue";
import screenWidthMixin from "../../../assets/JS/screenWidthMixin";
import StudioModal from "./StudioModal.vue";
import { mapGetters } from "vuex";
export default {
  name: "StudioDataLeft",
  components: { Filters, StudioModal },
  data() {
    return {
      cross: require("../../../assets/Images/Buttons/cross.png"),
      delete_icon: require("../../../assets/Images/delete btn.png"),
      addDashboard: require("../../../assets/Svg/add-dash.svg"),
      confirmDeleteDialog: false,
      confirmUpdateDialog: false,
      searchQuery: "",
      chartTypes: [],
      tableTypes: [],
      chartTitle: "",
      chartType: this.selectedChartType,
      xAxisItems: [],
      yAxisItems: [],
      zAxisItems: [],
      colorGroupItems: [],
      selectedXAxisKey: [],
      selectedYAxisKey: [],
      selectedZAxisKey: [],
      selectedColorGroupKey: null,
      xAxisData: [],
      yAxisData: [],
      zAxisData: [],
      colorGroupData: [],
      showXBinsInput: false,
      showXAxis: true,
      showYAxis: true,
      showZAxis: false,
      showColorGroup: false,
      xBins: 20,
      showYBinsInput: false,
      yBins: 20,
      chartTypesNames: [],
      tableTypesNames: [],
      selectedChartTypeName: null,
      selectedTableTypeName: null,
      selectedSavedChartName: null,
      savedCharts: [],
      savedChartsNames: [],
      tableData: [],
      showLoaders: false,
      deleteError: null,
      showTooltip: false,
      snackbar: {
        visible: false,
        message: "",
        color: "success",
        timeout: 3000,
      },
      createLoading: false,
      updateLoading: false,
      deleteLoading: false,
      loadingSavedCharts: false,
      loadingXAxisData: false,
      loadingYAxisData: false,
      loadingZAxisData: false,
      loadingTableType: false,
      loadingChartType: false,
      createNewChart: false,
      chartSaved: false,
      accessLevel: "private",
      isPublic: false,
      resetSavedFilter: false,
      callDefaultFilter: false,
      icon: require("../../../assets/Images//arrow-down.png"),
      icon2: require("../../../assets/Images/arrow-up.png"),
      showInputs: true,
      savedFilterData: [],
      savedType: "",
      colorGroupChanged: false,
      xAxisChanged: false,
      yAxisChanged: false,
      zAxisChanged: false,
      axisSelected: false,
      sampleIdPresent: false,
      filterFromChart: [],
      chartSearchQuery: "",
      tableTypeSearchQuery: "",
      chartTypeSearchQuery: "",
      filteredChartsNames: [],
      filteredTableTypesNames: [],
      filteredChartTypesNames: [],
      onlyApiCall: false,
      filterReset: false,
      filteredXAxisItems: [],
      filteredYAxisItems: [],
      filteredZAxisItems: [],
      filteredColorGroupItems: [],
      xAxisSearchQuery: "",
      yAxisSearchQuery: "",
      zAxisSearchQuery: "",
      colorGroupSearchQuery: "",
      xAxisAbortController: null,
      yAxisAbortController: null,
      zAxisAbortController: null,
      colorGroupAbortController: null,
      xAxisScale: "linear",
      yAxisScale: "linear",
      isXLog: false,
      isYLog: false,
      selectedChartId: null,
      confirmDeleteText: null,
      isUpdateEnabled: false,
      // timeoutMessage: "",
      // tableDataType: "",
    };
  },
  props: {
    selectedChartType: {
      type: String,
    },
  },
  mixins: [screenWidthMixin],
  computed: {
    ...mapGetters("tenantId", ["getUser"]),
    isAdmin() {
      return this.getUser && this.getUser.type === "admin";
    },
    isFluidsDataStudio() {
      return this.$route.path === '/Fluidsdata-studio';
    },
     isDashboard() {
      return this.$route.path === '/Dashboards/create' || this.$route.path.startsWith('/Dashboards/edit/');
    },
    updateXBins() {
      this.$emit("update-x-bins", Number(this.xBins));
      let savedChartData = JSON.parse(localStorage.getItem("savedChartData")) || JSON.parse(localStorage.getItem("newsavedChartData"));
      if (savedChartData?.NumXBins) {
        savedChartData.NumXBins = Number(this.xBins);
        if (localStorage.getItem("savedChartData")) {
          localStorage.setItem("savedChartData", JSON.stringify(savedChartData));
        } else {
          localStorage.setItem("newsavedChartData", JSON.stringify(savedChartData));
        }
      }
        // this.saveChartData();
    },
    updateYBins() {
      this.$emit("update-y-bins", Number(this.yBins));
      let savedChartData = JSON.parse(localStorage.getItem("savedChartData")) || JSON.parse(localStorage.getItem("newSavedChartData"));
      if (savedChartData?.NumYBins) {
        savedChartData.NumYBins = Number(this.yBins)
        localStorage.setItem("savedChartData", JSON.stringify(savedChartData));
      }
        this.saveChartData();
    },
    xBinsAsNumber() {
      return Number(this.xBins);
    },
    yBinsAsNumber() {
      return Number(this.yBins);
    },
    isFormValid() {
      const isBasicFieldsFilled =
        this.selectedChartTypeName &&
        this.chartTitle &&
        this.selectedTableTypeName;
      const isXAxisFilled = !this.showXAxis || this.selectedXAxisKey;
      const isYAxisFilled =
        !this.showYAxis ||
        (Array.isArray(this.selectedYAxisKey) &&
          this.selectedYAxisKey.length > 0);
      const isZAxisFilled = !this.showZAxis || this.selectedZAxisKey;
      return (
        isBasicFieldsFilled && isXAxisFilled && isYAxisFilled && isZAxisFilled
      );
    },
    shouldRenderChartList() {
      return this.shouldRenderComponent([
        "FluidsDataStudioTwo"
      ]);
    },
  },
  watch: {
    selectedChartTypeName: "updateIsUpdateEnabled",
  chartTitle: "updateIsUpdateEnabled",
  selectedTableTypeName: "updateIsUpdateEnabled",
  selectedXAxisKey: "updateIsUpdateEnabled",
  selectedYAxisKey: "updateIsUpdateEnabled",
  selectedZAxisKey: "updateIsUpdateEnabled",
  xBins: "updateIsUpdateEnabled",
  yBins: "updateIsUpdateEnabled",
  xAxisScale: "updateIsUpdateEnabled",
  yAxisScale: "updateIsUpdateEnabled",
  accessLevel: "updateIsUpdateEnabled",
  selectedColorGroupKey(newValue) {
    this.updateIsUpdateEnabled();
    if (newValue === "") {
      this.selectedColorGroupKey = null;
    }
  },
    rightContentStyle(newStyle) {
      this.$emit("update-right-content-style", newStyle);
    }
  },
  methods: {   
    handleXScaleToggleChange() {
  this.xAxisScale = this.isXLog ? "log" : "linear";
  this.$emit("x-axis-scale", this.xAxisScale);
},
    handleYScaleToggleChange() {
  this.yAxisScale = this.isYLog ? "log" : "linear";
  this.$emit("y-axis-scale", this.yAxisScale);
},
    resetFilterChartList() {
      this.chartSearchQuery = "";
      this.filteredChartsNames = this.savedChartsNames;
    },
    resetTableTypeList() {
      this.tableTypeSearchQuery = "";
      this.filteredTableTypesNames = this.tableTypesNames;
    },
    resetChartTypeList() {
      this.chartTypeSearchQuery = "";
      this.filteredChartTypesNames = this.chartTypesNames;
    },
    resetXAxisList() {
      this.xAxisSearchQuery = "";
      this.filteredXAxisItems = this.xAxisItems;
    },
    resetYAxisList() {
      this.yAxisSearchQuery = "";
      this.filteredYAxisItems = this.yAxisItems;
    },
    resetZAxisList() {
      this.zAxisSearchQuery = "";
      this.filteredZAxisItems = this.zAxisItems;
    },
    resetColorGroupList() {
      this.colorGroupSearchQuery = "";
      this.filteredColorGroupItems = this.colorGroupItems;
    },
    // Filter the chart list based on the search query
    filterChartList() {
      const query = this.chartSearchQuery.toLowerCase();
      this.filteredChartsNames = this.savedChartsNames.filter((chart) =>
        chart.toLowerCase().includes(query)
      );
    },
    // Filter the table list based on the search query
    filterTableTypeList() {
      const query = this.tableTypeSearchQuery.toLowerCase();
      this.filteredTableTypesNames = this.tableTypesNames.filter((chart) =>
        chart.toLowerCase().includes(query)
      );
    },
    // Filter the chart type list based on the search query
    filterChartTypeList() {
      const query = this.chartTypeSearchQuery.toLowerCase();
      this.filteredChartTypesNames = this.chartTypesNames.filter((chart) =>
        chart.toLowerCase().includes(query)
      );
    },
    // Filter the x-axis list based on the search query
    filterXAxisList() {
      const query = this.xAxisSearchQuery.toLowerCase();
      this.filteredXAxisItems = this.xAxisItems.filter((chart) =>
        chart.displayName.toLowerCase().includes(query)
      );
    },
    // Filter the y-axis list based on the search query
    filterYAxisList() {
      const query = this.yAxisSearchQuery.toLowerCase();
      this.filteredYAxisItems = this.yAxisItems.filter((chart) =>
        chart.displayName.toLowerCase().includes(query)
      );
    },
    // Filter the z-axis list based on the search query
    filterZAxisList() {
      const query = this.zAxisSearchQuery.toLowerCase();
      this.filteredZAxisItems = this.zAxisItems.filter((chart) =>
        chart.displayName.toLowerCase().includes(query)
      );
    },
    // Filter the color group list based on the search query
    filterColorGroupList() {
      const query = this.colorGroupSearchQuery.toLowerCase();
      this.filteredColorGroupItems = this.colorGroupItems.filter((chart) =>
        chart.displayName.toLowerCase().includes(query)
      );
    },
    updateIsUpdateEnabled() {
    this.isUpdateEnabled = this.checkIfUpdateEnabled();
  },
    handleUpdateClick() {
    this.isUpdateEnabled = this.checkIfUpdateEnabled(); // Recalculate on button click
    if (this.isUpdateEnabled) {
      this.showUpdateConfirmation(this.selectedChartId);
    }
  },
  checkIfUpdateEnabled() {
    let initialChartData = JSON.parse(localStorage.getItem("initialChartData"));

    if (!initialChartData) return false;

    if (!this.selectedChartTypeName || !this.chartTitle || !this.selectedTableTypeName) {
      return false;
    }

    const hasBasicFieldsChanged =
      this.selectedChartTypeName !== initialChartData.ChartType ||
      this.chartTitle !== initialChartData.Name ||
      this.selectedTableTypeName !== initialChartData.TableType;

    const hasXAxisChanged =
      this.showXAxis && this.selectedXAxisKey !== initialChartData.XAxisColumn;

    const hasYAxisChanged =
      this.showYAxis &&
      Array.isArray(this.selectedYAxisKey) &&
      this.selectedYAxisKey.join(",") !== initialChartData.YAxisColumn;

    const hasZAxisChanged =
      this.showZAxis && this.selectedZAxisKey !== initialChartData.ZAxisColumn;

    const hasColorGroupChanged =
      (this.selectedColorGroupKey || "") !== (initialChartData.GroupColumn || "");

    const hasXBinsChanged =
      (this.xBins || null) !== (initialChartData.NumXBins || null);

    const hasYBinsChanged =
      (this.yBins || null) !== (initialChartData.NumYBins || null);

    const hasXScaleChanged =
      (this.xAxisScale || "") !== (initialChartData.XAxisScale || "");

    const hasYScaleChanged =
      (this.yAxisScale || "") !== (initialChartData.YAxisScale || "");
      
    const hasAccessLevelChanged =
      this.accessLevel !== initialChartData.AccessLevel;

    const initialAssets = initialChartData.Filter.asset || [];
    const savedAssets = this.savedFilterData.asset ? Array.from(this.savedFilterData.asset) : [];

    const hasAssetFilterChanged =
      initialAssets.length !== savedAssets.length ||
      !initialAssets.every((item) => savedAssets.includes(item));

    const initialFields = initialChartData.Filter.field || [];
    const savedFields = this.savedFilterData.field ? Array.from(this.savedFilterData.field) : [];

    const hasFieldFilterChanged =
      initialFields.length !== savedFields.length ||
      !initialFields.every((item) => savedFields.includes(item));

    const initialSampleKinds = initialChartData.Filter.sampleKind || [];
    const savedSampleKinds = this.savedFilterData.sampleKind ? Array.from(this.savedFilterData.sampleKind) : [];

    const hasSampleKindFilterChanged =
      initialSampleKinds.length !== savedSampleKinds.length ||
      !initialSampleKinds.every((item) => savedSampleKinds.includes(item));

    const initialWells = initialChartData.Filter.well || [];
    const savedWells = this.savedFilterData.well ? Array.from(this.savedFilterData.well) : [];

    const hasWellFilterChanged =
      initialWells.length !== savedWells.length ||
      !initialWells.every((item) => savedWells.includes(item));

    const initialReservoirs = initialChartData.Filter.reservoir || [];
    const savedReservoirs = this.savedFilterData.reservoir ? Array.from(this.savedFilterData.reservoir) : [];

    const hasReservoirFilterChanged =
      initialReservoirs.length !== savedReservoirs.length ||
      !initialReservoirs.every((item) => savedReservoirs.includes(item));

    return (
      hasBasicFieldsChanged ||
      hasXAxisChanged ||
      hasYAxisChanged ||
      hasZAxisChanged ||
      hasColorGroupChanged ||
      hasXBinsChanged ||
      hasYBinsChanged ||
      hasXScaleChanged ||
      hasYScaleChanged ||
      hasAccessLevelChanged ||
      hasAssetFilterChanged ||
      hasFieldFilterChanged ||
      hasSampleKindFilterChanged ||
      hasWellFilterChanged ||
      hasReservoirFilterChanged
    );
  },
    async showDeleteConfirmation(chartID) {
      try {
        // Make an API call to check if the chart is used in any dashboards
        const response = await api.get(`/public/api/v1/charts/${chartID}/usage`);
        const data = response.data.data;
        if (data.length > 0) {
          // If used in multiple dashboards, ask for confirmation
          this.confirmDeleteText = `This plot is used in ${data.length} dashboards. After deletion, it will be removed from all dashboards. Do you still want to delete this plot?`;
          this.confirmDeleteDialog = true;
        } else {
          this.confirmDeleteText = "Are you sure you want to delete plot?";
          this.confirmDeleteDialog = true;
          //this.deleteChart(); // Proceed to delete if not used in multiple dashboards
        }
      } catch (error) {
        console.error('Error checking chart usage:', error);
      }
    },
    closeDeleteConfirmation() {
      this.confirmDeleteDialog = false;
    },
    async showUpdateConfirmation(chartID) {
      try {
        // Make an API call to check if the chart is used in any dashboards
        const response = await api.get(`/public/api/v1/charts/${chartID}/usage`);
        const data = response.data.data;
        if (data.length > 0) {
          // If used in multiple dashboards, ask for confirmation
          this.confirmUpdateText = `This plot is used in ${data.length} dashboards. Do you still want to update this plot? If you update this plot, this change will be reflected in all dashboards as well.`;
          this.confirmUpdateDialog = true;
        } else {
          this.confirmUpdateText = "Are you sure you want to update plot?";
          this.confirmUpdateDialog = true;
          //this.deleteChart(); // Proceed to delete if not used in multiple dashboards
        }
      } catch (error) {
        console.error('Error checking chart usage:', error);
      }
    },
    closeUpdateConfirmation() {
      this.confirmUpdateDialog = false;
    },
    openDialog() {
      if (this.$refs.childComponent) {
        this.$refs.childComponent.openDialog();
      } else {
        console.error("Child component not available");
      }
    },
    //   retryGetTableData() {
    //   let selectedType = this.tableDataType;
    //   this.getTableData(selectedType);
    // },
    toggleInputs(drawer) {
      console.log(drawer)
      this.$emit("handle-chart-height", drawer);
    },
    resetCallDefaultFilter() {
      this.callDefaultFilter = false;
    },
    callUpdateChartMethod() {
      this.updateChart()
    },
    handleResetSavedFilter() {
      this.filterReset = true;
      const savedChartData = localStorage.getItem("savedChartData") || localStorage.getItem("newsavedChartData");
      if (savedChartData) {
        const chartData = JSON.parse(savedChartData);
        if (chartData) {
          this.savedChartChangeLoad(chartData);
        }
      }
    },
    shouldRenderComponent(allowedRoutes) {
      return allowedRoutes.includes(this.$route.name);
    },
    showSnackbar(message, color = "success") {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.visible = true;
    },
    goToCreateChart() {
      this.resetChartData();
    },
    async tableTypeChange(selectedType) {
      this.loadingXAxisData = true;
      this.loadingYAxisData = true;
      this.loadingZAxisData = true;
      this.selectedTableTypeName = selectedType;
      const tableTypeString = JSON.stringify(selectedType);
      localStorage.setItem("selectedTableType", this.selectedTableTypeName)
      try {
        const response = await api.get(
          "public/api/v1/tables/type?table=" +
          encodeURIComponent(tableTypeString)
        );
        if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
          this.loadingXAxisData = false;
          this.loadingYAxisData = false;
          this.loadingZAxisData = false;
          const columns = response.data.data.tabletypes[0].columns.filter(column => column.displayName);
          columns.sort((a, b) => (a.columnSortOrder || 999) - (b.columnSortOrder || 999));
          this.xAxisItems = columns.filter(column => column.canChart)
            .map(column => ({ displayName: column.displayName, name: column.name, sortOrder: column.sortOrder || 999 })).sort((a, b) => a.sortOrder - b.sortOrder);
          this.filteredXAxisItems = this.xAxisItems
          this.yAxisItems = columns.filter(column => column.canChart)
            .map(column => ({ displayName: column.displayName, name: column.name, sortOrder: column.sortOrder || 999 })).sort((a, b) => a.sortOrder - b.sortOrder);
          this.filteredYAxisItems = this.yAxisItems
          this.zAxisItems = columns.filter(column => column.canChart)
            .map(column => ({ displayName: column.displayName, name: column.name, sortOrder: column.sortOrder || 999 })).sort((a, b) => a.sortOrder - b.sortOrder);
          this.filteredZAxisItems = this.zAxisItems
          this.colorGroupItems = columns.filter(column => column.legend)
            .map(column => ({ displayName: column.displayName, name: column.name, sortOrder: column.legendSortOrder || 999 })).sort((a, b) => a.sortOrder - b.sortOrder);
          this.filteredColorGroupItems = this.colorGroupItems
        }
        else if (response && response.data && response.data.message) {
          this.showSnackbar(response.data.message, "error");
        }
        else {
          this.showSnackbar("error fetching table types.", "error");
        }
        this.resetAxisSelections();
        this.saveChartData();
        let savedChartData = JSON.parse(localStorage.getItem("savedChartData"));
        if (savedChartData) {
          savedChartData.TableType = this.selectedTableTypeName
          localStorage.setItem("savedChartData", JSON.stringify(savedChartData));
        }
      } catch (error) {
        console.log("Error", error);
      }
    },
    async tableTypeChangeAgainstFilter(selectedType) {
      this.loadingXAxisData = true;
      this.loadingYAxisData = true;
      this.loadingZAxisData = true;
      this.resetAxisSelections();
      this.selectedTableTypeName = selectedType;
      const tableTypeString = JSON.stringify(selectedType);
      localStorage.setItem("selectedTableType", this.selectedTableTypeName)
      try {
        const response = await api.get(
          "public/api/v1/tables/type?table=" +
          encodeURIComponent(tableTypeString)
        );
        if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
          this.loadingXAxisData = false;
          this.loadingYAxisData = false;
          this.loadingZAxisData = false;
          const columns = response.data.data.tabletypes[0].columns.filter(column => column.displayName);
          columns.sort((a, b) => (a.columnSortOrder || 999) - (b.columnSortOrder || 999));
          this.xAxisItems = columns.filter(column => column.canChart)
            .map(column => ({ displayName: column.displayName, name: column.name, sortOrder: column.sortOrder || 999 })).sort((a, b) => a.sortOrder - b.sortOrder);
          this.filteredXAxisItems = this.xAxisItems
          this.yAxisItems = columns.filter(column => column.canChart)
            .map(column => ({ displayName: column.displayName, name: column.name, sortOrder: column.sortOrder || 999 })).sort((a, b) => a.sortOrder - b.sortOrder);
          this.filteredYAxisItems = this.yAxisItems
          this.zAxisItems = columns.filter(column => column.canChart)
            .map(column => ({ displayName: column.displayName, name: column.name, sortOrder: column.sortOrder || 999 })).sort((a, b) => a.sortOrder - b.sortOrder);
          this.filteredZAxisItems = this.zAxisItems
          this.colorGroupItems = columns.filter(column => column.legend)
            .map(column => ({ displayName: column.displayName, name: column.name, sortOrder: column.legendSortOrder || 999 })).sort((a, b) => a.sortOrder - b.sortOrder);
          this.filteredColorGroupItems = this.colorGroupItems
        }
        else if (response && response.data && response.data.message) {
          this.showSnackbar(response.data.message, "error");
        }
        else {
          this.showSnackbar("error fetching table types.", "error");
        }
      } catch (error) {
        console.log("Error", error);
      }
    },
    async getTableData(signal) {
      this.showLoaders = true;
      this.$emit("loading-state", this.showLoaders);
      this.$emit("sample-error", "");
      // Retrieve the selected table type from localStorage
      var selectedType = localStorage.getItem("selectedTableType");
      // Initialize an array for selected columns
      let selectedColumns = [];
      // Safely add each key to selectedColumns if it exists and is not empty
      if (typeof this.selectedXAxisKey === "string" && this.selectedXAxisKey.trim() !== "") {
        selectedColumns.push(this.selectedXAxisKey.trim());
      }
      if (Array.isArray(this.selectedYAxisKey)) {
        // If selectedYAxisKey is an array, add its non-empty trimmed values
        selectedColumns.push(
          ...this.selectedYAxisKey
            .filter((key) => typeof key === "string" && key.trim() !== "")
            .map((key) => key.trim())
        );
      } else if (typeof this.selectedYAxisKey === "string" && this.selectedYAxisKey.trim() !== "") {
        selectedColumns.push(this.selectedYAxisKey.trim());
      }
      if (typeof this.selectedZAxisKey === "string" && this.selectedZAxisKey.trim() !== "") {
        selectedColumns.push(this.selectedZAxisKey.trim());
      }
      if (typeof this.selectedColorGroupKey === "string" && this.selectedColorGroupKey.trim() !== "") {
        selectedColumns.push(this.selectedColorGroupKey.trim());
      }
      // Filter out any invalid entries as an extra safety measure
      selectedColumns = selectedColumns.filter((column) => column);
      // If no columns are selected, exit early
      if (selectedColumns.length === 0) {
        return;
      }
      try {
        // Construct the API URL with the selectColumns query parameter
        const queryString = `?selectColumns=${JSON.stringify(selectedColumns)}`;
        const response = await api.get(
          `public/api/v1/tables/data/${selectedType}${queryString}`,
          { signal }
        );
        // Check if the API response is successful
        if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
          if (response.data.error) {
            this.$emit("sample-error", response.data.error);
            return
          }
          this.tableData = response.data.data.TableData.rows;
          if (this.tableData === null) {
            this.$emit("sample-error", "Data is not available to plot chart.");
            return
          }
        }
        else if (response && response.data && response.data.message) {
          this.$emit("sample-error", response.data.message);
        }
        else if (response && response.data && response.data.error) {
          this.$emit("sample-error", response.data.error);
        }
        else {
          this.$emit("sample-error", "error fetching table data.");
        }
      } catch (error) {
        console.log("Error", error);
        // Handle error as needed
      }
      finally {
        this.showLoaders = false;
        this.$emit("loading-state", this.showLoaders);
      }
    },
    async getTableDataAgainstFilter({ filterData, type, filterCalled }, signal) {
      if (filterCalled == true) {
        this.onlyApiCall = false;
      }
      
      var selectedType = localStorage.getItem("selectedTableType")
      this.savedFilterData = filterData
      this.savedType = selectedType
      this.$emit("sample-error", "");
      this.showLoaders = true;
      this.$emit("loading-state", this.showLoaders);
      try {
        const queryParams = {};
        const minMaxFormatter = ({ min = "", max = "" }) => JSON.stringify({ min, max });
        // Add filters to queryParams only if they have values
        if (filterData.asset && filterData.asset.length) queryParams.asset = JSON.stringify(filterData.asset);
        if (filterData.field && filterData.field.length) queryParams.field = JSON.stringify(filterData.field);
        if (filterData.well && filterData.well.length) queryParams.well = JSON.stringify(filterData.well);
        if (filterData.reservoir && filterData.reservoir.length) queryParams.reservoir = JSON.stringify(filterData.reservoir);
        if (filterData.sampleKind && filterData.sampleKind.length) queryParams.sampleKind = JSON.stringify(filterData.sampleKind);
        if (filterData.fluidSampleID && filterData.fluidSampleID.length) queryParams.fluidSampleID = JSON.stringify(filterData.fluidSampleID);
        if (filterData.fluidSampleContainerID && filterData.fluidSampleContainerID.length) queryParams.fluidSampleContainerID = JSON.stringify(filterData.fluidSampleContainerID);
        if (filterData.source) queryParams.source = `"${filterData.source}"`;
        if (filterData.sampleDate) queryParams.sampleDate = minMaxFormatter(filterData.sampleDate);
        if (filterData.depth) queryParams.depth = minMaxFormatter(filterData.depth);
        if (filterData.formationPressure) queryParams.formationPressure = minMaxFormatter(filterData.formationPressure);
        if (filterData.formationTemperature) queryParams.formationTemperature = minMaxFormatter(filterData.formationTemperature);
        // Add selectColumns logic here
        let selectedColumns = [];
        // Add selected X/Y/Z & color group keys
        if (typeof this.selectedXAxisKey === "string" && this.selectedXAxisKey.trim() !== "") {
          selectedColumns.push(this.selectedXAxisKey.trim());
        }
        if (Array.isArray(this.selectedYAxisKey)) {
          selectedColumns.push(
            ...this.selectedYAxisKey
              .filter((key) => typeof key === "string" && key.trim() !== "")
              .map((key) => key.trim())
          );
        } else if (typeof this.selectedYAxisKey === "string" && this.selectedYAxisKey.trim() !== "") {
          selectedColumns.push(this.selectedYAxisKey.trim());
        }
        if (typeof this.selectedZAxisKey === "string" && this.selectedZAxisKey.trim() !== "") {
          selectedColumns.push(this.selectedZAxisKey.trim());
        }
        if (typeof this.selectedColorGroupKey === "string" && this.selectedColorGroupKey.trim() !== "") {
          selectedColumns.push(this.selectedColorGroupKey.trim());
        }
        const chartsData = localStorage.getItem("savedChartData") || localStorage.getItem("newsavedChartData");
        const chartData = JSON.parse(chartsData);
        if (chartData) {
          // Add X Axis column if valid
          if (typeof chartData.XAxisColumn === "string" && chartData.XAxisColumn.trim() !== "") {
            selectedColumns.push(chartData.XAxisColumn.trim());
          }
          // Handle Y Axis column
          if (typeof chartData.YAxisColumn === "string" && chartData.YAxisColumn.trim() !== "") {
            // Split the string by commas, trim each value, and push to selectedColumns
            selectedColumns.push(
              ...chartData.YAxisColumn.split(",").map((key) => key.trim())
            );
          } else if (Array.isArray(chartData.YAxisColumn)) {
            // Push directly if it's an array
            selectedColumns.push(
              ...chartData.YAxisColumn
                .filter((key) => typeof key === "string" && key.trim() !== "")
                .map((key) => key.trim())
            );
          }
          // Add Z Axis key if valid
          if (typeof chartData.ZAxisColumn === "string" && chartData.ZAxisColumn.trim() !== "") {
            selectedColumns.push(chartData.ZAxisColumn.trim());
          }
        }
        this.chartTypeChange(chartData?.ChartType)
        // Add color group key if valid
        if (typeof chartData.GroupColumn === "string" && chartData.GroupColumn.trim() !== "") {
          selectedColumns.push(chartData.GroupColumn.trim());
        }

        // Add selectColumns to queryParams
        if (selectedColumns.length > 0) {
          queryParams.selectColumns = JSON.stringify(selectedColumns);
        }
        const queryString = Object.entries(queryParams)
          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
          .join("&");
        const response = await api.get(`public/api/v1/tables/data/${selectedType}?${queryString}`,
          { signal }
        );
        if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
          if (response.data.error) {
            this.$emit("sample-error", response.data.error);
            return
          }
          this.tableData = response.data.data.TableData.rows;
          if (this.onlyApiCall === true) {
            return;
          }
          if (this.tableData === null) {
            this.$emit("sample-error", "Data is not available to plot chart.");
            return
          }
          const savedChartData = localStorage.getItem("savedChartData") || localStorage.getItem("newsavedChartData");
          const chartData = JSON.parse(savedChartData);
          
          if (chartData) {
            this.chartTitle = chartData.Name;
            var chartTitle = this.chartTitle;
            this.$emit("chart-title", chartTitle);
            this.selectedChartTypeName = chartData.ChartType;
            this.selectedTableTypeName = chartData.TableType;
            this.selectedColorGroupKey = chartData.GroupColumn;
            this.selectedXAxisKey = chartData.XAxisColumn;
            this.selectedZAxisKey = chartData.ZAxisColumn;
            this.xBins = 0;
            this.xBins = chartData.NumXBins;
            this.yBins = 0;
            this.yBins = chartData.NumYBins;
            this.$emit("update-x-bins", Number(this.xBins));
            this.$emit("update-y-bins", Number(this.yBins));
            if (chartData.YAxisColumn) {
              var yAxisColumnArray = chartData.YAxisColumn.split(",").filter(item => item.trim() !== ""); // Remove any empty values
              if (this.selectedColorGroupKey && yAxisColumnArray.length > 1) {
                yAxisColumnArray.pop();
                this.selectedYAxisKey = yAxisColumnArray;
              }
              //  else if (!this.selectedColorGroupKey && yAxisColumnArray.length > 1) {
              //   this.selectedYAxisKey = yAxisColumnArray;
              // }
              else {
                this.selectedYAxisKey = yAxisColumnArray;
              }
            }
          }
          // }
          // else {
          var chartTitle = this.chartTitle
          this.$emit("chart-title", chartTitle);
          this.$nextTick(() => {
            if (this.selectedXAxisKey) {
              const selectedColumn = this.xAxisItems.find(
                (item) => item.name === this.selectedXAxisKey
              );
              this.xAxisData = this.findXAxisData();
              var allCaptions = this.tableData?.map((row) => row.caption || ""); // Extract captions
              this.$emit("x-axis-selected", { data: this.xAxisData, displayName: selectedColumn ? selectedColumn.displayName : this.selectedXAxisKey, caption: allCaptions });
              this.handleGroupSelection()
            }
            else {
              this.colorGroupData = [];
              this.$emit("grouped-x-axis-data", { groupedXAxisData: [] });
            }
            if (this.selectedYAxisKey.length > 0) {
              if (this.selectedYAxisKey.length > 1) {
                this.selectedColorGroupKey = null;
              }
              this.yAxisData = this.findYAxisData();
              var allCaptions = this.tableData?.map((row) => row.caption || ""); // Extract captions
              const yAxisDisplayNames = this.selectedYAxisKey.map((key) => {
                const selectedColumn = this.yAxisItems.find(
                  (item) => item.name === key
                );
                return selectedColumn ? selectedColumn.displayName : key;
              });
              this.$emit("y-axis-selected", { data: this.yAxisData, displayNames: yAxisDisplayNames, caption: allCaptions });
              this.handleGroupSelection();
            }
            else {
              // Handle case when no items are selected for the y-axis
              this.yAxisData = [];
              this.$emit("y-axis-selected", { data: [], displayNames: [] }); // Emit empty data
              this.colorGroupData = [];
              this.$emit("grouped-y-axis-data", { groupedYAxisData: [] });
            }
            if (this.selectedZAxisKey) {
              const selectedColumn = this.zAxisItems.find(
                (item) => item.name === this.selectedZAxisKey
              );
              this.zAxisData = this.findZAxisData();
              this.$emit("z-axis-selected", { data: this.zAxisData, displayName: selectedColumn ? selectedColumn.displayName : this.selectedZAxisKey });
              this.handleGroupSelection();
            }
            else {
              this.colorGroupData = [];
              this.$emit("grouped-z-axis-data", { groupedZAxisData: [] });
            }
          });
          // }
        }
        else if (response && response.data && response.data.message) {
          this.$emit("sample-error", response.data.message);
        }
        else if (response && response.data && response.data.error) {
          this.$emit("sample-error", response.data.error);
        }
        else {
          // Handle unexpected response structure
          this.$emit("sample-error", "Unexpected response structure.");
        }
      } catch (error) {
        if (error.response && error.response.data) {
          let sampleError = error.response.data.error;
          this.$emit("sample-error", sampleError);
        } else {
          this.$emit("sample-error", "An error occurred while fetching data.");
        }
      } finally {
        this.showLoaders = false;
        this.$emit("loading-state", this.showLoaders);
      }
    },
    chartTypeChange(selectedType) {
      this.chartType = selectedType;
      const selectedChart = this.chartTypes.find(
        (chart) => chart.Name === selectedType
      );
      if (selectedChart) {
        const originalSavedChartData = JSON.parse(localStorage.getItem("savedChartData"));
        let savedChartData = originalSavedChartData || JSON.parse(localStorage.getItem("newsavedChartData"));
        if (savedChartData && savedChartData?.Name !== "" && savedChartData?.Name !== null) {
          this.chartTitle = savedChartData.Name
        }
        this.showXBinsInput = selectedChart.has_x_bins;
        if (this.showXBinsInput) {
          if (savedChartData && savedChartData?.NumXBins) {
            this.xBins = savedChartData.NumXBins
          }
          else if (savedChartData) {
            if (this.chartType === "Histogram") {
              this.xBins = 10;
              this.$emit("update-x-bins", Number(this.xBins));
            }
            else {
              this.xBins = 20;
              this.$emit("update-x-bins", Number(this.xBins));
            }
          }
        } 
        else {
          // Remove NumXBins from savedChartData if it exists
          if (savedChartData && savedChartData?.NumXBins) {
            delete savedChartData.NumXBins;
            if (originalSavedChartData) {
              localStorage.setItem("savedChartData", JSON.stringify(savedChartData));
            }
          }
        }
        this.showYBinsInput = selectedChart.has_y_bins;
        if (this.showYBinsInput) {
          if (savedChartData?.NumYBins) {
            this.yBins = savedChartData.NumYBins
          }
          else if (savedChartData) {
            this.yBins = 20;
            savedChartData.NumYBins = this.yBins;
            if (originalSavedChartData) {
              localStorage.setItem("savedChartData", JSON.stringify(savedChartData));
            }
          }
        }
        else {
          // Remove NumYBins from savedChartData if it exists
          if (savedChartData?.NumYBins) {
            delete savedChartData.NumYBins;
            if (originalSavedChartData) {
              localStorage.setItem("savedChartData", JSON.stringify(savedChartData));
            }
          }
        }
        this.showColorGroup = selectedChart.has_group
        if (this.showColorGroup == true && savedChartData && savedChartData?.GroupColumn !== "" && savedChartData?.GroupColumn !== null && this.colorGroupChanged == false) {
          this.selectedColorGroupKey = savedChartData.GroupColumn
        }
        if (this.colorGroupChanged == true) {
          savedChartData.GroupColumn = this.selectedColorGroupKey
        }
        if (this.showColorGroup == false) {
          this.selectedColorGroupKey = null;
          this.$emit("grouped-y-axis-data", { groupedYAxisData: [] });
          this.$emit("grouped-x-axis-data", { groupedXAxisData: [] });
          this.$emit("grouped-z-axis-data", { groupedZAxisData: [] });
        }
        this.selectedChartTypeName = selectedChart.Name;
        this.showXAxis = selectedChart.has_x;
        if (this.showXAxis == true && savedChartData && savedChartData?.XAxisColumn !== "" && savedChartData?.XAxisColumn !== null && this.xAxisChanged == false) {
          this.selectedXAxisKey = savedChartData.XAxisColumn
        }
        if (this.xAxisChanged == true && this.selectedXAxisKey) {
          savedChartData.XAxisColumn = this.selectedXAxisKey
        }
        this.showYAxis = selectedChart.has_y;
          var yAxisColumnArray = savedChartData?.YAxisColumn?.split(",").filter(item => item.trim() !== ""); // Remove any empty values
        if (this.showYAxis == true && savedChartData && savedChartData?.YAxisColumn !== "" && savedChartData?.YAxisColumn !== null && this.yAxisChanged == false) {
          if (originalSavedChartData) {
            if (this.selectedColorGroupKey && yAxisColumnArray?.length > 1) {
              yAxisColumnArray.pop();
              this.selectedYAxisKey = yAxisColumnArray;
            }
            if (!this.selectedColorGroupKey && yAxisColumnArray?.length > 1) {
              this.selectedYAxisKey = yAxisColumnArray;
            }
            else {
              this.selectedYAxisKey = yAxisColumnArray;
            }
          }
        }
        if (this.yAxisChanged == true) {
          if (originalSavedChartData) {
            if (this.selectedColorGroupKey && yAxisColumnArray?.length > 1) {
              yAxisColumnArray.pop();
              yAxisColumnArray = this.selectedYAxisKey;
            }
            if (!this.selectedColorGroupKey && yAxisColumnArray?.length > 1) {
              yAxisColumnArray = this.selectedYAxisKey;
            }
            else {
              yAxisColumnArray = this.selectedYAxisKey;
            }
          }
        }
        if (this.showYAxis == false && savedChartData.ChartType !== 'Histogram') {
          this.selectedYAxisKey = [];
          this.handleYAxisSelection();
          if (savedChartData) {
            savedChartData.YAxisColumn = this.selectedYAxisKey?.join(",")
            if (originalSavedChartData) {
              localStorage.setItem("savedChartData", JSON.stringify(savedChartData));
          }
        }
      }
        this.showZAxis = selectedChart.has_z;
        if (this.showZAxis == true && savedChartData && savedChartData?.ZAxisColumn !== "" && savedChartData?.ZAxisColumn !== null && this.zAxisChanged == false) {
          this.selectedZAxisKey = savedChartData.ZAxisColumn
        }
        if (this.zAxisChanged == true) {
          savedChartData.ZAxisColumn = this.selectedZAxisKey
        }
        this.$emit("chart-type-changed", selectedType);
        this.saveChartData();
        if (savedChartData) {
          savedChartData.ChartType = selectedType
          if (originalSavedChartData) {
            localStorage.setItem("savedChartData", JSON.stringify(savedChartData));
          }
        }
      } else {
        console.error("Selected chart not found.");
      }
    },
    async savedChartChange() {
      this.showLoaders = true;
      this.$emit("loading-state", this.showLoaders);
      let defaultFilterID = localStorage.getItem("defaultFilter");
      const selectedChart = this.savedCharts.find(
        (chart) => chart.Name === this.selectedSavedChartName
      );
      if (selectedChart) {
        const chartId = selectedChart.ID;
        this.selectedChartId = selectedChart.ID;
        try {
          const response = await api.get(`public/api/v1/charts/${chartId}`);
          if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
            let selectedChartData = response.data.data.chart;
            this.accessLevel = selectedChartData.AccessLevel;
            this.isPublic = this.accessLevel === "public";
            this.isXLog = selectedChartData.XAxisScale === "log";
            this.handleXScaleToggleChange();
            this.isYLog = selectedChartData.YAxisScale === "log";
            this.handleYScaleToggleChange();
            await this.tableTypeChange(selectedChartData.TableType);
            localStorage.setItem("savedChartData", JSON.stringify(selectedChartData));
            localStorage.setItem("initialChartData", JSON.stringify(selectedChartData));
            this.filterFromChart = selectedChartData.Filter;
            if (this.filterFromChart) {
              if (this.filterFromChart.ID !== undefined) {
                localStorage.setItem("selectedFilterId", this.filterFromChart.ID)
              }
              // this.$refs.filters.isFilterSelected();
              return
            }
            if (selectedChart && defaultFilterID) {
              this.callDefaultFilter = true;
              return;
            }
            this.showLoaders = false;
            this.$emit("loading-state", this.showLoaders);
            this.$nextTick(() => {
              this.populateChartData(selectedChartData);
            });
            this.createNewChart = true;
          }
          else if (response && response.data && response.data.message) {
            this.showSnackbar(response.data.message, "error");
          }
          else {
            this.showSnackbar("error fetching chart data.", "error");
          }
        } catch (error) {
          console.log("Error", error);
        }
      } else {
        console.error("Selected chart not found.");
      }
    },
    async savedChartChangeLoad(chartData) {
      this.$emit("sample-error", "");
      this.showLoaders = true;
      this.$emit("loading-state", this.showLoaders);
      const savedChartSelected = localStorage.getItem("savedChartData")
      if (savedChartSelected) {
        this.selectedSavedChartName = chartData.Name;
      }
      let selectedChartData = chartData;
      await this.tableTypeChange(selectedChartData.TableType);
      this.accessLevel = selectedChartData.AccessLevel
      this.isPublic = this.accessLevel === "public";
      this.isXLog = selectedChartData.XAxisScale === "log";
            this.handleXScaleToggleChange();
            this.isYLog = selectedChartData.YAxisScale === "log";
            this.handleYScaleToggleChange();
      let defaultFilterID = localStorage.getItem("defaultFilter");
      if (this.filterReset === false) {
        this.filterFromChart = chartData.Filter;
        if (this.filterFromChart) {
          if (this.filterFromChart.ID !== undefined) {
            localStorage.setItem("selectedFilterId", this.filterFromChart.ID)
          }
          return;
        }
      }
      else if (this.filterReset === true) {
        this.filterFromChart = null
      }
      if (defaultFilterID) {
        return;
      }
      this.showLoaders = false;
      this.$emit("loading-state", this.showLoaders);
      this.$nextTick(() => {
        this.populateChartData(selectedChartData);
      });
      this.createNewChart = true;
    },
    updateChartTitle() {
      var chartTitle = this.chartTitle;
      this.$emit("chart-title", chartTitle);
    },
    async populateChartData(chartData) {
      this.chartTitle = chartData.Name;
      var chartTitle = this.chartTitle;
      this.$emit("chart-title", chartTitle);
      this.selectedChartTypeName = chartData.ChartType;
      this.selectedTableTypeName = chartData.TableType;
      this.selectedColorGroupKey = chartData.GroupColumn;
      const selectedXAxisItem = this.xAxisItems.find(
        (item) => item.name === chartData.XAxisColumn
      );
      this.selectedXAxisKey = selectedXAxisItem ? selectedXAxisItem.name : null;

      if (this.tableData && chartData.XAxisColumn) {
        var columnData = this.tableData.map(
          (row) => row[chartData.XAxisColumn]
        );
        var allCaptions = this.tableData?.map((row) => row.caption || ""); // Extract caption data
        this.xAxisData = columnData;
        this.$emit("x-axis-selected", { data: this.xAxisData, displayName: columnData ? columnData.displayName : chartData.XAxisColumn, caption: allCaptions });
        this.handleGroupSelection()
      }
      else {
        this.colorGroupData = [];
        this.$emit("grouped-x-axis-data", { groupedXAxisData: [] });
      }
      if (typeof chartData.YAxisColumn === "string" && chartData.YAxisColumn.length > 0) {
        var yAxisColumnArray = chartData.YAxisColumn.split(",");
        if (this.selectedColorGroupKey && yAxisColumnArray.length > 1) {
          yAxisColumnArray.pop(); // Remove the last element if selectedColorGroupKey is present
        }
        this.selectedYAxisKey = yAxisColumnArray.map((yAxisColumnName) => {
          const selectedYAxisItem = this.yAxisItems.find(
            (item) => item.name === yAxisColumnName
          );
          return selectedYAxisItem ? selectedYAxisItem.name : null;
        });
        if (this.tableData && chartData.YAxisColumn.length > 0) {
          var columnData = yAxisColumnArray.map((col) => {
            return this.tableData.map((row) => row[col]);
          });
          this.yAxisData = columnData;
          var allCaptions = this.tableData?.map((row) => row.caption || ""); // Extract caption data
          this.$emit("y-axis-selected", { data: this.yAxisData, caption: allCaptions });
          this.handleGroupSelection();
        }
      } else {
        this.selectedYAxisKey = [];
      }
      const selectedZAxisItem = this.zAxisItems.find(
        (item) => item.name === chartData.ZAxisColumn
      );
      this.selectedZAxisKey = selectedZAxisItem ? selectedZAxisItem.name : null;

      this.chartTypeChange(this.selectedChartTypeName);
      this.xBins = chartData.NumXBins;
      this.yBins = chartData.NumYBins;
      if (this.showXBinsInput) {
        this.updateXBins;
      }
      if (this.showYBinsInput) {
        this.updateYBins;
      }
      this.updateAxisSelections();
      // localStorage.setItem("savedChartData", JSON.stringify(chartData));
    },
    updateAxisSelections() {
      this.handleXAxisSelection();
      this.handleYAxisSelection();
      this.handleZAxisSelection();
    },
    // findXAxisData() {
    //   if (this.tableData && this.selectedXAxisKey) {
    //     var columnData = this.tableData.map(
    //       (row) => row[this.selectedXAxisKey]
    //     );
    //     return columnData;
    //   }
    //   return [];
    // },
    findYAxisData() {
      if (this.tableData && this.selectedYAxisKey.length > 0) {
        var columnData = this.selectedYAxisKey.map((col) => {
          const decimalKey = `${col}_decimal`;
          return this.tableData.map((row) => {
            if (row[decimalKey]) {
              return parseFloat(row[col]).toFixed(row[decimalKey]);
            } else {
              return row[col];
            }
          });
        });
        return columnData;
      }
      return [];
    },
    findXAxisData() {
      if (this.tableData && this.selectedXAxisKey) {
        const decimalKey = `${this.selectedXAxisKey}_decimal`;
        return this.tableData.map((row) => {
          if (row[decimalKey]) {
            return parseFloat(row[this.selectedXAxisKey]).toFixed(row[decimalKey]);
          } else {
            return row[this.selectedXAxisKey];
          }
        });
      }
      return [];
    },
    findZAxisData() {
      if (this.tableData && this.selectedZAxisKey) {
        const decimalKey = `${this.selectedZAxisKey}_decimal`;
        return this.tableData.map((row) => {
          if (row[decimalKey]) {
            return parseFloat(row[this.selectedZAxisKey]).toFixed(row[decimalKey]);
          } else {
            return row[this.selectedZAxisKey];
          }
        });
      }
      return [];
    },
    findcolorGroupData() {
      if (this.tableData && this.selectedColorGroupKey) {
        var columnData = this.tableData.map(row => row[this.selectedColorGroupKey]);
        const uniqueValues = [...new Set(columnData.filter(item => item !== undefined && item !== null))];
        return uniqueValues;
      }
      return [];
    },
    saveChartData() {
      const chartData = {
        AccessLevel: this.accessLevel,
        ChartType: this.selectedChartTypeName || "",
        GroupColumn: this.selectedColorGroupKey || null,
        Name: this.chartTitle || "", // Use the current chart title
        TableType: this.selectedTableTypeName || "",
        XAxisColumn: this.selectedXAxisKey || null,
        YAxisColumn: this.selectedYAxisKey?.join(",") || null, // Join multiple Y-Axis keys
        ZAxisColumn: this.selectedZAxisKey || null,
        ...(this.selectedChartTypeName === "Histogram" || this.selectedChartTypeName === "2D Histogram"
      ? {
          NumXBins: Number(this.xBins),
          NumYBins: Number(this.yBins),
        }
      : {}),
      };
      localStorage.setItem("newsavedChartData", JSON.stringify(chartData));
    },
    handleXSelection() {
      this.xAxisChanged = true;
      this.handleXAxisSelection()
    },
    async handleXAxisSelection() {
      this.axisSelected = true;
      if (this.selectedXAxisKey) {
        // Cancel any pending X-axis API request
        if (this.yAxisChanged === false) {
        if (this.xAxisAbortController) {
          this.xAxisAbortController.abort();
        }
        // Create a new AbortController for the current X-axis request
        this.xAxisAbortController = new AbortController();
        const signal = this.xAxisAbortController.signal;

        if (this.sampleIdPresent && this.showColorGroup) {
          this.selectedColorGroupKey = "FluidSampleID";
        }
        let defaultFilterID = localStorage.getItem("defaultFilter");
        let filterActive = localStorage.getItem("filterActive")
        let selectedFilterId = localStorage.getItem("selectedFilterId")
        this.saveChartData();   
        if (defaultFilterID || filterActive || selectedFilterId) {
          let filterData = this.savedFilterData
          let type = this.savedType
          this.onlyApiCall = true
          await this.getTableDataAgainstFilter({ filterData, type }, signal);
        }
        else {
          await this.getTableData(signal);
        }
        }
        const selectedColumn = this.xAxisItems.find(
          (item) => item.name === this.selectedXAxisKey
        );
        this.xAxisData = this.findXAxisData();
        var allCaptions = this.tableData?.map((row) => row.caption || ""); // Extract caption data
        this.$emit("x-axis-selected", { data: this.xAxisData, displayName: selectedColumn ? selectedColumn.displayName : this.selectedXAxisKey, caption: allCaptions });
        if (this.yAxisChanged === false) {
        this.handleGroupSelection()
        }
        let savedChartData = JSON.parse(localStorage.getItem("savedChartData"));
        if (savedChartData) {
          savedChartData.XAxisColumn = this.selectedXAxisKey
          localStorage.setItem("savedChartData", JSON.stringify(savedChartData));
        }
        this.xAxisChanged = false;
      }
      else {
        this.colorGroupData = [];
        this.$emit("grouped-x-axis-data", { groupedXAxisData: [] });
        this.xAxisChanged = false;
      }
    },
    handleYSelection() {
      this.yAxisChanged = true;
      this.handleYAxisSelection()
    },
    async handleYAxisSelection() {
      this.handleXAxisSelection();
      this.axisSelected = true;
      if (this.selectedYAxisKey.length > 0) {
        // Cancel any pending Y-axis API request
        if (this.yAxisAbortController) {
          this.yAxisAbortController.abort();
        }

        // Create a new AbortController for the current Y-axis request
        this.yAxisAbortController = new AbortController();
        const signal = this.yAxisAbortController.signal;

        let defaultFilterID = localStorage.getItem("defaultFilter");
        let filterActive = localStorage.getItem("filterActive")
        let selectedFilterId = localStorage.getItem("selectedFilterId")
        this.saveChartData();
        if (defaultFilterID || filterActive || selectedFilterId) {
          let filterData = this.savedFilterData
          let type = this.savedType
          this.onlyApiCall = true
          await this.getTableDataAgainstFilter({ filterData, type }, signal);
        }
        else {
          await this.getTableData(signal);
        }
        if (this.selectedYAxisKey.length > 1) {
          this.selectedColorGroupKey = null;
          let savedChartData = JSON.parse(localStorage.getItem("savedChartData"));
          if (savedChartData) {
            savedChartData.GroupColumn = this.selectedColorGroupKey
            localStorage.setItem("savedChartData", JSON.stringify(savedChartData));
          }
        }
        this.yAxisData = this.findYAxisData();
        var allCaptions = this.tableData?.map((row) => row.caption || ""); // Extract caption data
        const yAxisDisplayNames = this.selectedYAxisKey.map((key) => {
          const selectedColumn = this.yAxisItems.find(
            (item) => item.name === key
          );
          return selectedColumn ? selectedColumn.displayName : key;
        });
        this.$emit("y-axis-selected", { data: this.yAxisData, displayNames: yAxisDisplayNames, caption: allCaptions });
        this.handleGroupSelection();
        let savedChartData = JSON.parse(localStorage.getItem("savedChartData"));
        if (savedChartData) {
          savedChartData.YAxisColumn = this.selectedYAxisKey.join(",")
          localStorage.setItem("savedChartData", JSON.stringify(savedChartData));
        }
        this.yAxisChanged = false;
      }
      else {
        // Handle case when no items are selected for the y-axis
        this.yAxisData = [];
        this.$emit("y-axis-selected", { data: [], displayNames: [] }); // Emit empty data
        this.colorGroupData = [];
        this.$emit("grouped-y-axis-data", { groupedYAxisData: [] });
        let savedChartData = JSON.parse(localStorage.getItem("savedChartData"));
        if (savedChartData) {
          savedChartData.YAxisColumn = ""
          localStorage.setItem("savedChartData", JSON.stringify(savedChartData));
        }
        this.yAxisChanged = false;
      }
    },
    handleZSelection() {
      this.zAxisChanged = true;
      this.handleZAxisSelection()
    },
    async handleZAxisSelection() {
      this.axisSelected = true;
      if (this.selectedZAxisKey) {
        // Cancel any pending Z-axis API request
        if (this.zAxisAbortController) {
          this.zAxisAbortController.abort();
        }
        // Create a new AbortController for the current Z-axis request
        this.zAxisAbortController = new AbortController();
        const signal = this.zAxisAbortController.signal;

        let defaultFilterID = localStorage.getItem("defaultFilter");
        let filterActive = localStorage.getItem("filterActive")
        let selectedFilterId = localStorage.getItem("selectedFilterId")
        this.saveChartData();
        if (defaultFilterID || filterActive || selectedFilterId) {
          let filterData = this.savedFilterData
          let type = this.savedType
          this.onlyApiCall = true
          await this.getTableDataAgainstFilter({ filterData, type }, signal);
        }
        else {
          await this.getTableData(signal);
        }
        const selectedColumn = this.zAxisItems.find(
          (item) => item.name === this.selectedZAxisKey
        );
        this.zAxisData = this.findZAxisData();
        this.$emit("z-axis-selected", { data: this.zAxisData, displayName: selectedColumn ? selectedColumn.displayName : this.selectedZAxisKey });
        this.handleGroupSelection();
        let savedChartData = JSON.parse(localStorage.getItem("savedChartData"));
        if (savedChartData) {
          savedChartData.ZAxisColumn = this.selectedZAxisKey
          localStorage.setItem("savedChartData", JSON.stringify(savedChartData));
        }
        this.zAxisChanged = false;
      }
      else {
        this.colorGroupData = [];
        this.$emit("grouped-z-axis-data", { groupedZAxisData: [] });
        this.zAxisChanged = false;
      }
    },
    handleColorGroupSelection() {
      this.axisSelected = true;
      this.colorGroupChanged = true;
      this.handleGroupSelection()
    },
    async handleGroupSelection() {
      if (this.selectedColorGroupKey && this.selectedColorGroupKey.length > 0) {
        if (this.colorGroupChanged) {
          
          // Cancel any pending color grouping API request
          if (this.colorGroupAbortController) {
            this.colorGroupAbortController.abort();
          }
          // Create a new AbortController for the current color grouping request
          this.colorGroupAbortController = new AbortController();
          const signal = this.colorGroupAbortController.signal;

          let defaultFilterID = localStorage.getItem("defaultFilter");
          let filterActive = localStorage.getItem("filterActive")
          let selectedFilterId = localStorage.getItem("selectedFilterId")
          this.saveChartData();
          if (defaultFilterID || filterActive || selectedFilterId) {
            let filterData = this.savedFilterData
            let type = this.savedType
            this.onlyApiCall = true
            await this.getTableDataAgainstFilter({ filterData, type }, signal);
          }
          else {
            await this.getTableData(signal);
          }
        }
        this.colorGroupChanged = false
        this.colorGroupData = this.findcolorGroupData();

        if (this.selectedChartTypeName != "Histogram") {
          // Check if yAxisData is present
          if (!this.yAxisData.length) {
            return;
          }
        }
        // Check if xAxisData is present
        if (!this.xAxisData.length) {
          return;
        }
        if (this.selectedYAxisKey.length > 1) {
          this.selectedYAxisKey = this.selectedYAxisKey.slice(0, 1);
          let savedChartData = JSON.parse(localStorage.getItem("savedChartData"));
          if (savedChartData) {
            savedChartData.YAxisColumn = this.selectedYAxisKey.join(",")
            localStorage.setItem("savedChartData", JSON.stringify(savedChartData));
          }
          const yAxisDisplayNames = this.selectedYAxisKey.map(key => {
            const selectedColumn = this.yAxisItems.find(item => item.name === key);
            return selectedColumn ? selectedColumn.displayName : key;
          });
          this.$emit("y-axis-selected", { displayNames: yAxisDisplayNames });
        }
        // Initialize an object to store grouped yAxisData based on uniqueValues
        const groupedYAxisData = {};
        const groupedXAxisData = {};
        const groupedZAxisData = {};
        // Initialize the grouped arrays for each unique value
        this.colorGroupData.forEach(value => {
          groupedYAxisData[value] = [];
          groupedXAxisData[value] = [];
          groupedZAxisData[value] = [];
        });
        // Iterate through tableData and group yAxisData and xAxisData
        this.tableData.forEach(row => {
          const colorGroupValue = row[this.selectedColorGroupKey];
          if (this.colorGroupData.includes(colorGroupValue)) {
            // Collect yAxis data
            const yAxisRowData = this.selectedYAxisKey.map(key => {
              const decimalKey = `${key}_decimal`;
              return row[decimalKey] ? parseFloat(row[key]).toFixed(row[decimalKey]) : row[key];
            });
            groupedYAxisData[colorGroupValue].push(yAxisRowData);
            // Collect xAxis data
            const xAxisRowData = row[this.selectedXAxisKey];
            groupedXAxisData[colorGroupValue].push(xAxisRowData);
            // Collect zAxis data
            const zAxisRowData = row[this.selectedZAxisKey];
            groupedZAxisData[colorGroupValue].push(zAxisRowData);
          }
        });
        let selectedColorGroupKey = this.selectedColorGroupKey
        // Emit the grouped yAxis and xAxis data
        this.$emit("grouped-y-axis-data", (groupedYAxisData));
        this.$emit("grouped-x-axis-data", (groupedXAxisData));
        this.$emit("grouped-z-axis-data", (groupedZAxisData));
        this.$emit("color-group-key", (selectedColorGroupKey));
        let savedChartData = JSON.parse(localStorage.getItem("savedChartData"));
        if (savedChartData) {
          savedChartData.GroupColumn = this.selectedColorGroupKey
          localStorage.setItem("savedChartData", JSON.stringify(savedChartData));
        }
      }
      else {
        // Handle case when no items are selected for the y-axis
        this.colorGroupData = [];
        this.$emit("grouped-y-axis-data", { groupedYAxisData: [] });
        this.$emit("grouped-x-axis-data", { groupedXAxisData: [] });
        this.$emit("grouped-z-axis-data", { groupedZAxisData: [] });
        this.yAxisData = this.findYAxisData();
        const yAxisDisplayNames = this.selectedYAxisKey.map(key => {
          const selectedColumn = this.yAxisItems.find(item => item.name === key);
          return selectedColumn ? selectedColumn.displayName : key;
        });
        this.$emit("y-axis-selected", { data: this.yAxisData, displayNames: yAxisDisplayNames });
        let savedChartData = JSON.parse(localStorage.getItem("savedChartData"));
        if (savedChartData) {
          savedChartData.GroupColumn = ""
          localStorage.setItem("savedChartData", JSON.stringify(savedChartData));
        }
      }
    },
    resetAxisSelections() {
      this.selectedXAxisKey = [];
      this.selectedYAxisKey = [];
      this.selectedZAxisKey = [];
      const containsSampleUID = this.colorGroupItems.some(
        item => item.displayName === "Sample UID"
      );
      if (containsSampleUID) {
        this.sampleIdPresent = true;
        // this.selectedColorGroupKey = "SampleID";
      } else {
        this.selectedColorGroupKey = null;
      }
      this.$emit("grouped-y-axis-data", { groupedYAxisData: [] });
      this.$emit("grouped-x-axis-data", { groupedXAxisData: [] });
      this.$emit("grouped-z-axis-data", { groupedZAxisData: [] });
      this.xAxisData = [];
      this.$emit("x-axis-selected", { data: this.xAxisData });
      this.yAxisData = [];
      this.$emit("y-axis-selected", { data: this.yAxisData });
      this.zAxisData = [];
      this.$emit("z-axis-selected", { data: this.zAxisData });
    },
    async getChartTypes() {
      this.loadingChartType = true;
      await api
        .get("public/api/v1/charts/type")
        .then((response) => {
          if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
            this.chartTypes = response.data.data.chart_types;
            this.chartTypesNames = response.data.data.chart_types.map(
              (chart) => chart.Name
            );
            this.filteredChartTypesNames = this.chartTypesNames
          }
          else if (response && response.data && response.data.message) {
            this.showSnackbar(response.data.message, "error");
          }
          else {
            this.showSnackbar("error fetching chart types.", "error");
          }
        })
        .catch((error) => {
          console.log("Error", error);
        })
        .finally(() => {
          this.loadingChartType = false;
        });
    },
    getTableTypes() {
      this.showLoaders = true;
      this.loadingTableType = true;
      api
        .get("public/api/v1/tables/type")
        .then((response) => {
          if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
            this.tableTypes = response.data.data.tabletypes;
            this.tableTypesNames = response.data.data.tabletypes.map(
              (chart) => chart.table
            );
            this.filteredTableTypesNames = this.tableTypesNames;
          }
          else if (response && response.data && response.data.message) {
            this.showSnackbar(response.data.message, "error");
          }
          else {
            this.showSnackbar("error fetching table types.", "error");
          }
        })
        .catch((error) => {
          console.log("Error", error);
        })
        .finally(() => {
          this.showLoaders = false;
          this.loadingTableType = false;
        });
    },
    handleToggleChange() {
      // Update access level based on the switch state
      this.accessLevel = this.isPublic ? "public" : "private";
    },
    createChart() {
      this.createLoading = true;
      const chartType = this.selectedChartTypeName
        ? this.selectedChartTypeName
        : "";
      const tableType = this.selectedTableTypeName
        ? this.selectedTableTypeName
        : "";
      const selectedChartType = this.chartTypes.find(
        (chart) => chart.Name === chartType
      );
      // Initialize YAxisColumns as an array and include selectedYAxisKey values if showYAxis is true
      let YAxisColumns = this.showYAxis ? [...this.selectedYAxisKey] : [];

      // Add GroupColumn data to YAxisColumns if available
      if (this.showColorGroup && this.selectedColorGroupKey) {
        YAxisColumns.push(this.selectedColorGroupKey);
      }
      var Filters = {};
      var lastView = localStorage.getItem("lastView");
      if (lastView == "/Fluidsdata-studio") {
        Filters = this.$refs.filters.saveFilterWithChart();
      }
      const requestData = {
        data: {
          chart: {
            name: this.chartTitle,
            ChartType: chartType,
            TableType: tableType,
            XAxisColumn: this.selectedXAxisKey,
            AccessLevel: this.accessLevel,
            Filter: Filters,
            XAxisScale: this.xAxisScale,
            YAxisScale: this.yAxisScale,
          },
        },
      };
      if (this.showYAxis) {
        requestData.data.chart.YAxisColumns = YAxisColumns;
      }
      if (this.showZAxis) {
        requestData.data.chart.ZAxisColumn = this.selectedZAxisKey;
      }
      if (selectedChartType && selectedChartType.has_x_bins) {
        requestData.data.chart.NumXBins = this.xBinsAsNumber;
      }
      if (selectedChartType && selectedChartType.has_y_bins) {
        requestData.data.chart.NumYBins = this.yBinsAsNumber;
      }
      if (this.showColorGroup && this.selectedColorGroupKey) {
        requestData.data.chart.GroupColumn = this.selectedColorGroupKey
      }
      if (this.showColorGroup && !this.selectedColorGroupKey) {
        // requestData.data.chart.GroupColumn = ""
      }
      api
        .post("public/api/v1/charts", requestData)
        .then((response) => {
          if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
            this.createLoading = false;
            this.accessLevel = requestData.data.chart.AccessLevel;
            this.showSnackbar(response.data.message, "success");
            this.getSavedCharts();
            // this.resetChartData();
            this.$emit("chart-saved-studio", requestData.data.chart);
            this.createNewChart = true;
            this.selectedSavedChartName = requestData.data.chart.name;
            let chartId = response.data.data;
            this.getChartById(chartId);
          }
          else if (response && response.data && response.data.message) {
            this.showSnackbar(response.data.message, "error");
          }
          else {
            this.showSnackbar("error creating chart.", "error");
          }
          localStorage.removeItem("newsavedChartData");
        })
        .catch((error) => {
          this.createLoading = false;
          this.showSnackbar(error.response.data.error, "error");
        });
    },
    async deleteChart() {
      const selectedChart = this.savedCharts.find(
        (chart) => chart.Name === this.selectedSavedChartName
      );
      if (!selectedChart) {
        console.error("Selected chart not found.");
        return;
      }
      this.deleteLoading = true;
      const chartId = selectedChart.ID;
      try {
        const response = await api.delete(`public/api/v1/charts/${chartId}`);

        if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
          this.deleteLoading = false;
          this.showSnackbar(response.data.message, "success");
          this.getSavedCharts();
          this.resetChartData();
          this.chartSaved = true;
          this.$emit("chart-saved", this.chartSaved);
          this.confirmDeleteDialog = false;
        }
        else if (response && response.data && response.data.statusCode === 500) {
          this.deleteLoading = false;
          this.showSnackbar(response.data.error, "error");
        }
        else if (response && response.data && response.data.message) {
          this.showSnackbar(response.data.message, "error");
        }
        else {
          this.showSnackbar("error deleting chart.", "error");
        }
      } catch (error) {
        this.deleteLoading = false;
        this.showSnackbar(error.response.data.error, "error");
      }
    },
    async updateChart() {
      const selectedChart = this.savedCharts.find(
        (chart) => chart.Name === this.selectedSavedChartName
      );
      if (!selectedChart) {
        console.error("Selected chart not found.");
        return;
      }
      this.updateLoading = true;
      const chartId = selectedChart.ID;
      const chartType = this.selectedChartTypeName
        ? this.selectedChartTypeName
        : "";
      const tableType = this.selectedTableTypeName
        ? this.selectedTableTypeName
        : "";
      const selectedChartType = this.chartTypes.find(
        (chart) => chart.Name === chartType
      );
      let YAxisColumns = this.showYAxis ? [...this.selectedYAxisKey] : [];
      // Add GroupColumn data to YAxisColumns if available
      if (this.showColorGroup && this.selectedColorGroupKey) {
        YAxisColumns.push(this.selectedColorGroupKey);
      }
      const Filters = this.$refs.filters.saveFilterWithChart();
      const requestData = {
        data: {
          chart: {
            name: this.chartTitle,
            ChartType: chartType,
            TableType: tableType,
            XAxisColumn: this.selectedXAxisKey,
            AccessLevel: this.accessLevel,
            Filter: Filters,
            XAxisScale: this.xAxisScale,
            YAxisScale: this.yAxisScale,
          },
        },
      };
      if (this.showYAxis) {
        requestData.data.chart.YAxisColumns = YAxisColumns;
      }
      if (this.showZAxis) {
        requestData.data.chart.ZAxisColumn = this.selectedZAxisKey;
      }
      if (selectedChartType && selectedChartType.has_x_bins) {
        requestData.data.chart.NumXBins = this.xBinsAsNumber;
      }
      if (selectedChartType && selectedChartType.has_y_bins) {
        requestData.data.chart.NumYBins = this.yBinsAsNumber;
      }
      if (this.showColorGroup && this.selectedColorGroupKey) {
        requestData.data.chart.GroupColumn = this.selectedColorGroupKey
      }
      if (this.showColorGroup && !this.selectedColorGroupKey) {
        requestData.data.chart.GroupColumn = ""
      }
      try {
        const response = await api.put(
          `public/api/v1/charts/${chartId}`,
          requestData
        );
        if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
          this.updateLoading = false;
          this.accessLevel = requestData.data.chart.AccessLevel;
          this.showSnackbar(response.data.message, "success");
          this.getSavedCharts();
          // this.resetChartData();
          this.chartSaved = true;
          var chartSaved = this.chartSaved;
          this.$emit("chart-saved", chartSaved);
          this.getChartById(chartId);
          this.confirmUpdateDialog = false;
        }
        else if (response && response.data && response.data.message) {
          this.showSnackbar(response.data.message, "error");
        }
        else {
          this.showSnackbar("error updating chart.", "error");
        }
      } catch (error) {
        this.updateLoading = false;
        this.showSnackbar(error.response.data.error, "error");
      }
    },
    async getChartById(chartId) {
      this.selectedChartId = chartId;
      try {
        const response = await api.get(
          `public/api/v1/charts/${chartId}`
        );
        if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
          let chartDataById = response.data.data.chart;
          this.selectedSavedChartName = chartDataById.Name
          localStorage.setItem("savedChartData", JSON.stringify(chartDataById));
          localStorage.setItem("initialChartData", JSON.stringify(chartDataById));
          this.isUpdateEnabled = false;
      //     this.$nextTick(() => {
      //   this.isUpdateEnabled = this.checkIfUpdateEnabled();
      // });
          if (chartDataById.Filter.ID !== undefined) {
            localStorage.setItem("selectedFilterId", chartDataById.Filter.ID)
          }
        }
        else if (response && response.data && response.data.message) {
          this.showSnackbar(response.data.message, "error");
        }
        else {
          this.showSnackbar("error fetching chart data.", "error");
        }
      } catch (error) {
        console.log(error)
      }
    },
    resetChartData() {
      this.showLoaders = true;
      this.chartTitle = "";
      this.resetAxisSelections();
      this.$emit("chart-type-changed", "");
      this.$emit("chart-title", "");
      this.showXBinsInput = false;
      this.showYBinsInput = false;
      this.showZAxis = false;
      this.showColorGroup = false;
      this.selectedTableTypeName = null;
      this.selectedChartTypeName = null;
      this.selectedXAxisKey = [];
      this.selectedYAxisKey = [];
      this.selectedZAxisKey = [];
      this.selectedSavedChartName = null;
      this.showLoaders = false;
      this.createNewChart = false;
      this.accessLevel = "private",
        this.isPublic = this.accessLevel === "public";
        this.xAxisScale = "linear";
      this.yAxisScale = "linear";
      this.isXLog = false;
      this.isYLog = false;
      this.$emit("x-axis-scale", this.xAxisScale);
      this.$emit("y-axis-scale", this.yAxisScale);
      localStorage.removeItem("savedChartData");
      localStorage.removeItem("initialChartData");
      localStorage.removeItem("selectedFilterId");
      this.showInputs = true;
      this.$refs.filters.resetFields();
    },
    getSavedCharts() {
      this.showLoaders = true;
      this.loadingSavedCharts = true;
      api
        .get("public/api/v1/charts")
        .then((response) => {
          if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
            this.savedCharts = response.data.data.charts;
            this.savedChartsNames = response.data.data.charts.map(
              (chart) => chart.Name
            );
            this.filteredChartsNames = this.savedChartsNames;
          }
          else if (response && response.data && response.data.message) {
            this.showSnackbar(response.data.message, "error");
          }
          else {
            this.showSnackbar("error fetching charts.", "error");
          }
        })
        .catch((error) => {
          console.log("Error", error);
        })
        .finally(() => {
          this.showLoaders = false;
          this.loadingSavedCharts = false;
        });
    },
  },
  async mounted() {
    this.isUpdateEnabled = this.checkIfUpdateEnabled();
    localStorage.removeItem("newsavedChartData");
    await this.getChartTypes();
    this.getTableTypes();
    this.getSavedCharts();
    if (this.$route.path === '/Fluidsdata-studio') {
      const savedChartData = localStorage.getItem("savedChartData");
      if (savedChartData) {
        const chartData = JSON.parse(savedChartData);
        if (chartData && chartData.ID) {
          this.selectedChartId = chartData.ID;
          this.savedChartChangeLoad(chartData);
        }
      }
    }
    if (this.$route.path === '/Dashboards') {
      this.showInputs = true;
      this.chartTitle = "";
      this.selectedTableTypeName = null;
      this.selectedChartTypeName = null;
      this.selectedXAxisKey = [];
      this.selectedYAxisKey = [];
      this.selectedZAxisKey = [];
      this.selectedColorGroupKey = null;
    }
  },
};
</script>