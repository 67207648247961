<template>
  <div class="dashboard-page-wrapper test-page">
    <div class="top-wrap">
      <div class="text-img">
        <h2> Constant Volume Depletion </h2>
        <div v-if="receivedData">
          <div v-if="pdfLoader === true" class="test-pdf-div">
            <div class="img-content">
            <img :src="pdf_loader_image" alt="loader Image" />
            </div>
          </div>
          <div v-else-if="receivedData.testID !== ''" @click="goToPdf()" class="test-pdf-div">
            <div class="img-content">
            <img :src="pdf_icon" alt="pdf icon" />
            </div>
            <div class="pdf-text">Download PDF</div>
          </div>
        </div>
      </div>
      <div class="top-content" v-if="receivedData">
        <v-img src="../../../assets/Images/Assets/top image.png" alt="test image" width="49px" height="49px"></v-img>
        <div class="fields-info" :style="{
          flexDirection: (receivedData.saturationPressure && receivedData.testTemperature) ? 'row' : 'column',
          gap: (receivedData.saturationPressure && receivedData.testTemperature) ? '24px' : '8px'
        }" >
        <div class="fields-info-wrap">
            <div>
              <p>Test ID</p>
              <p class="test-top-values">{{ receivedData.testID }}</p>
            </div>
            <div v-if="receivedData.fluidSampleID">
              <p>Sample ID</p>
              <p class="test-top-values">{{ receivedData.fluidSampleID }}</p>
            </div>
          </div>
          <div class="fields-info-wrap">
            <div v-if="receivedData.saturationPressure_UOM && receivedData.saturationPressure">
              <p>Saturation Pressure</p>
              <p class="test-top-values">{{
                receivedData.saturationPressure }}
                <span> ({{
                  receivedData.saturationPressure_UOM }})
                </span>
              </p>
            </div>
            <div v-if="receivedData.testTemperature && receivedData.testTemperature_UOM">
              <p>Test Temperature</p>
              <p class="test-top-values">{{
                receivedData.testTemperature }}
                <span> ({{
                  receivedData.testTemperature_UOM }})
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="top-content" v-else>
        <test-loaders />
      </div>
    </div>
    <div ref="scrollable" class="tabs-data" tabindex="0" @keydown="handleKeyDown">
      <div class="tabs-toggle">
        <v-tabs v-model="tab">
          <v-tab value="one">Cvd Test Properties</v-tab>
          <v-tab value="two">Evolved Gas Compositions</v-tab>
          <v-tab value="three">Last Stage Liquid Compositions</v-tab>
        </v-tabs>

        <div v-if="tab === 'three'">
          <v-switch v-model="toggleFractionParent" color="info" inset :label="toggleFractionParent ? 'wt %' : 'mole %'"
            @change="handleToggleChange" hide-details></v-switch>
        </div>
      </div>



      <v-card-text class="pa-0 pt-4">
        <v-window v-model="tab">
          <v-window-item value="one">
            <CvdTestProperties @data-updated="handleDataUpdate" />
          </v-window-item>

          <v-window-item value="two">
            <ProducedGasCompositions />
          </v-window-item>

          <v-window-item value="three">
            <LastStageLiquidCompositions @toggle-fraction="updateToggleFractionComp" ref="lastStageLiquidCompositions" />
          </v-window-item>
        </v-window>
      </v-card-text>
    </div>
    <v-snackbar v-model="snackbarMessage" color="#E53535" timeout="3000" v-if="snackbarMessage != null">
      Error : {{ snackbarMessage }}
    </v-snackbar>
    <!-- <Filters @drawerStateChanged="handleRightDrawerStateChange" /> -->
  </div>
</template>

<script>
import CvdTestProperties from "./CvdTestProperties.vue";
import CvdGasProperties from "./CvdGasProperties.vue";
import TestLoaders from '../Loaders/TestLoaders.vue';
import ProducedGasCompositions from './ProducedGasCompositions.vue';
import LastStageLiquidCompositions from './LastStageLiquidCompositions.vue';
// import Filters from "../../../components/layout/Common/Filters.vue";
// import screenWidthMixin from "../../../assets/JS/screenWidthMixin";
import axios from "axios";
export default {
  components: { CvdTestProperties, CvdGasProperties, TestLoaders, ProducedGasCompositions, LastStageLiquidCompositions, },
  name: "CvdTabs",
  data: () => ({
    toggleFractionParent: true,
    tab: null,
    receivedData: null,
    assetName: "",
    fieldName: "",
    wellName: "",
    pdf_icon: require("../../../assets/Images/pdfIcon.svg"),
    pdf_loader_image: require("../../../assets/Images/pdfLoader.gif"),
    pdfLoader: false,
    snackbarMessage: null,
  }),
  // mixins: [screenWidthMixin],
  methods: {
    handleDataUpdate(data) {
      this.receivedData = data;
    },
    updateToggleFractionComp(value) {
      this.toggleFractionParent = value; // Update the parent state when child emits an event
    },
    handleToggleChange() {
      // Access the child's method through $refs
      this.$refs.lastStageLiquidCompositions.toggleFraction = this.toggleFractionParent; // Sync state with the child
      this.$refs.lastStageLiquidCompositions.toggleFractionFunction(); // Call the child's 
    },
    goToPdf() {
      this.snackbarMessage = null;
      this.pdfLoader = true;
      const tenantId = localStorage.getItem("tenantId");
      const sampleId = localStorage.getItem("sampleId");
      axios
        .get(process.env.VUE_APP_API_URL + `public/api/v1/samples/${sampleId}/download`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "X-TenantID": tenantId,
          },
          responseType: 'blob',
        })
        .then((response) => {
          const contentType = response.headers["content-type"];
          // If the content is not a PDF, treat it as an error
          if (!contentType || contentType !== "application/pdf") {
            // Handle the error case
            this.pdfLoader = false;
            this.snackbarMessage = "The PDF report is not available for synthetic data in this study/sample/test. Please explore tests/samples under Asset: Public Data to check the power of this feature.";
          }
          else {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            this.pdfLoader = false;
            window.open(url, '_blank');
          }
        })
        .catch((error) => {
          if (error.response) {
            this.pdfLoader = false;
            this.snackbarMessage = "The PDF report is not available for synthetic data in this study/sample/test. Please explore tests/samples under Asset: Public Data to check the power of this feature."
          }
        })
        .finally(() => {
          this.pdfLoader = false;
        });
    },
    handleKeyDown(event) {
      const key = event.key;
      if (key === 'ArrowDown') {
        // Handle scrolling down
        this.$refs.scrollable.scrollTop += 50; // You can adjust the scrolling speed as needed
      } else if (key === 'ArrowLeft') {
        // Handle switching to the previous tab
        const currentIndex = this.tab ? ['one', 'two', 'three'].indexOf(this.tab) : 0;
        this.tab = currentIndex === 0 ? 'three' : ['one', 'two', 'three'][currentIndex - 1];
      } else if (key === 'ArrowRight') {
        // Handle switching to the next tab
        const currentIndex = this.tab ? ['one', 'two', 'three'].indexOf(this.tab) : 0;
        this.tab = currentIndex === 2 ? 'one' : ['one', 'two', 'three'][currentIndex + 1];
      }
    },
  },
  mounted() {
    this.tab = 'one';
    this.assetName = this.$route.params.asset.replace(/-/g, ' ');
    this.fieldName = this.$route.params.field.replace(/-/g, ' ');
    this.wellName = this.$route.params.well;
    this.$refs.scrollable.focus();
  },
};
</script>