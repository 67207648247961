<template>
  <div class="eda-dashboard">
    <div v-if="error" class="error-message">
      {{ error }}
    </div>
    <iframe
      v-else
      :src="edaUrl"
      class="eda-iframe"
      ref="edaIframe"
      @load="handleIframeLoad"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "EDADashboard",
  data() {
    return {
      error: null,
    };
  },
  computed: {
    edaUrl() {
      const token = localStorage.getItem("authToken");
      console.log(token,'token');
      const tenantId = localStorage.getItem("tenantId");
      console.log(tenantId,'tenantId');
      
      if (!token || !tenantId) {
        this.error = "Authentication required";
        return null;
      }

      const edaBaseUrl = process.env.VUE_APP_EDA_URL;
      var url = `${edaBaseUrl}?token=${token}&tenantId=${tenantId}`;
      console.log(url,'url');
      return url;
    }
  },
  methods: {
    handleIframeLoad() {
      const iframe = this.$refs.edaIframe;
      if (iframe) {
        this.error = null;
      }
    }
  }
};
</script>

<style scoped>
.eda-dashboard {
  width: 100%;
  height: calc(100vh - 64px);
  position: relative;
}

.eda-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.error-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 20px;
  color: #dc3545;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
</style> 