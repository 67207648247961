<template>
  <div>
    <div class="main-form">
      <div class="logo-img">
        <v-img :src="logo" alt="logo"></v-img>
      </div>
      <div class="form-wrapper">
        <div class="top-ui">
          <h1 v-if="!loggedIn">Welcome back to Fluidsdata</h1>
          <h1 v-else>Select your Tenant Data</h1>
          <p v-if="!loggedIn">
            Sign in and unlock the power of your fluids characterization data and AI.
          </p>
        </div>
        <div class="form-info">
          <div class="inputs-wrap" v-if="loggedIn && getTenantData && getTenantData.length > 0">
            <div class="input-info">
              <label>Tenants Data</label>
              <v-select
                v-model="selectedTenant"
                variant="solo"
                class="custom-text-field"
                :items="getTenantData.map((tenant) => tenant.name)"
                placeholder="Please select tenant"
                @update:modelValue="onSelectTenant"
                hide-details
              ></v-select>
            </div>
          </div>
          <v-form v-else ref="form" v-model="form">
            <div class="inputs-wrap">
              <div class="input-info">
                <label>Email Address</label>
                <v-text-field
                  type="email"
                  variant="solo"
                  v-model="email"
                  :rules="[required, emailRule]"
                  placeholder="Email Address here"
                  hide-details
                  @keyup.enter="handleEnterKey"
                   class="custom-text-field"
                ></v-text-field>
              </div>
              <div class="input-info">
                <label>Password</label>
                <v-text-field
                  :type="show ? 'text' : 'password'"
                  hide-details
                   class="custom-text-field"
                  variant="solo"
                  placeholder="Password here"
                  :rules="[required, passwordRule]"
                  v-model="password"
                  @keyup.enter="handleEnterKey"
                >
                  <template #append>
                    <img
                      :src="show ? eyeClosed : eyeOpen"
                      @click="togglePasswordVisibility"
                      alt="Toggle Password Visibility"
                      style="cursor: pointer; width: 24px; height: 24px;"
                    />
                  </template>
                </v-text-field>
              </div>
              <!-- <div class="remember-forgot">
                <div>
                  <input type="checkbox" v-model="checked" />
                  <span> Remember Me</span>
                </div>
                <a href="#">Forgot Password?</a>
              </div> -->
            </div>
          </v-form>
        </div>
        <v-btn
        v-if="!loggedIn"
          class="login-btn"
          :disabled="loading || !isFormValid"
          :loading="loading"
          block
          @click="validateAndLogin"
          :class="{ filled: isFormValid }"
          >Login</v-btn
        >
      </div>
    </div>
    <!-- <v-dialog
      v-model="showModal"
      max-width="600"
      transition="dialog-top-transition"
      persistent
      class="tenant-dialog"
    >
      <v-card>
        <v-card-title class="px-0 d-flex align-center justify-between"
          >Tenant Data
          <v-spacer></v-spacer>
          <v-btn
            @click="closeModal"
            flat
            icon
            class="chart-preview-close-btn"
            size="x-small"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="height: 200px">
          <div v-if="showLoader == true" class="tenant-sec">
            <v-progress-circular
              color="primary"
              size="48"
              indeterminate
            ></v-progress-circular>
          </div>
          <v-select
            v-else-if="
              getTenantData && getTenantData.length > 1 && showLoader == false
            "
            @update:modelValue="onSelectTenant"
            v-model="selectedTenant"
            :items="getTenantData.map((tenant) => tenant.name)"
            placeholder="Please select tenant"
          >
          </v-select>
          <div
            v-else-if="getTenantError && showLoader == false"
            class="tenant-sec timeout"
          >
            <div class="text-center" v-html="getTenantError"></div>
            <v-btn flat @click="callTenants()" class="save-btn text-none"
              >Reload</v-btn
            >
          </div>
          <v-list v-else>
            <v-list-item> No tenant data available. </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog> -->
    <v-dialog
      transition="dialog-top-transition"
      width="417"
      v-model="showAlert"
      persistent
    >
      <v-alert title="Previous Session Detected">
        <p class="session-info">
          Would you like to continue from where you left off ?
        </p>
        <div class="alert-btns-div">
          <v-btn class="cancel-btn" flat @click="goToAssets">No</v-btn>
          <v-btn class="save-btn" flat @click="goToLastView">Yes</v-btn>
        </div>
      </v-alert>
    </v-dialog>
    <v-snackbar
      v-model="snackbarMessage"
      color="#E53535"
      timeout="3000"
      v-if="getLoginError"
    >
      Error : {{ getLoginError }}
    </v-snackbar>
    <v-snackbar
      v-model="timeoutMessage"
      color="#E53535"
      timeout="3000"
      v-if="getLoginError"
    >
      <div class="text-center" v-html="getLoginError"></div>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { fetchMetaData } from "../../../utils/cache";
export default {
  name: "Form",
  data: () => ({
    logo: require("../../../assets/Images/Auth/logo.png"),
    eyeOpen: require("../../../assets/Svg/eye.svg"),
    eyeClosed: require("../../../assets/Svg/eye-slash.svg"),
    show: false,
    form: false,
    email: null,
    password: null,
    loading: false,
    showModal: false,
    selectedTenant: null,
    snackbarMessage: null,
    timeoutMessage: null,
    showPassword: false,
    showAlert: false,
    showLoader: true,
    loggedIn: false,
  }),
  computed: {
    ...mapGetters("tenantId", ["getTenantData", "getSelectedTenant"]),
    getLoginError() {
      const error = this.$store.getters["auth/getLoginError"];
      if (
        typeof error === "string" &&
        error &&
        error.includes("Request timeout. Try again")
      ) {
        this.timeoutMessage = error;
      } else {
        this.snackbarMessage = error;
      }
      return error;
    },
    getTenantError() {
      const error = this.$store.getters["tenantId/getTenantError"];
      if (error && error.includes("Request timeout. Try again")) {
        return error;
      }
    },
    passwordRule() {
      return (v) => {
        if (!v) return "Password is required";
        if (v.length < 8 || v.length > 15)
          return "Password must be between 8 and 15 characters";
        return true;
      };
    },
    emailRule() {
      return (v) => {
        if (!v) return "Email is required";
        const validEmail = this.validateEmail(v);
        if (!validEmail) return "Invalid email address";
        return true;
      };
    },
    isFormValid() {
      const isEmailValid = this.emailRule(this.email) === true;
      const isPasswordValid = this.passwordRule(this.password) === true;
      const isValid = isEmailValid && isPasswordValid;
      return isValid;
    },
  },
  methods: {
    ...mapActions("auth", ["sendLoginRequest", "sendLogoutRequest"]),
    // ...mapActions("metaData", ["fetchMetaData"]),
    togglePasswordVisibility() {
      console.log("Call");
      this.show = !this.show;
    },
    handleEnterKey() {
      // Check if both fields have valid data before calling login
      const emailValid = this.email && this.emailRule(this.email) === true;
      const passwordValid = this.password && this.passwordRule(this.password) === true;

      if (emailValid && passwordValid) {
        this.validateAndLogin();
      } else {
        console.log("Validation failed: Cannot proceed to login.");
      }
    },
    validateAndLogin() {
      this.loading = true;
      this.login();
    },
    login() {
      if (!this.isFormValid) return;
      this.loading = true;
      this.sendLoginRequest({
        username: this.email,
        password: this.password,
      })
        .then(async (response) => {
          if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
            this.loggedIn = true;
            await this.callTenants();
          }
          else if (response && response.data && response.data.message) {
          console.error(response.data.message);
          }
          else {
            console.error("Error in login");
          }
        })
        .catch((error) => {
          console.log("Error in login:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async callTenants() {
      // this.showModal = true;
      this.showLoader = true;
      this.$store
        .dispatch("tenantId/fetchTenants")
        .then(async () => {
          var existingTenantId = localStorage.getItem("tenantId");
          if (this.getTenantData && this.getTenantData.length === 1) {
            localStorage.setItem("tenantId", this.getTenantData[0].ID);
            localStorage.setItem("tenantName", this.getTenantData[0].name);
            await fetchMetaData();
            if (existingTenantId === this.getTenantData[0].ID) {
              // If the selected tenant ID matches the existing tenant ID, show the confirmation message
              var lastView = localStorage.getItem("lastView");
              if (lastView) {
                this.showAlert = true;
              } else {
                this.$router.push("/data-management");
              }
            } else {
              // If the selected tenant ID does not match the existing tenant ID, navigate to /assets
              this.$router.push("/data-management");
            }
          } else if (this.getTenantData && this.getTenantData.length > 1) {
            // this.showModal = true;
          }
        })
        .catch((error) => {
          console.error("Error fetching tenants:", error);
        })
        .finally(() => {
          this.loading = false;
          this.showLoader = false;
        });
    },

    logOut() {
      this.sendLogoutRequest()
        .then((response) => {
          if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
            this.$router.push("/login");
          }
          else if (response && response.data && response.data.message) {
          console.error(response.data.message);
          }
          else {
            console.error("Error in logout");
          }
        })
        .catch((error) => {
          console.error("Error in logout:", error);
        });
    },

    async onSelectTenant(selectedValue) {
      const selectedTenant = this.getTenantData.find(
        (tenant) => tenant.name === selectedValue
      );

      if (selectedTenant) {
        const { name, ID } = selectedTenant;
        var existingTenantId = localStorage.getItem("tenantId"); // Retrieve the existing tenant ID from local storage

        localStorage.setItem("tenantId", ID);
        localStorage.setItem("tenantName", name);
        this.$store.commit("tenantId/SET_SELECTED_TENANT", { name, ID });
        await fetchMetaData();
        if (existingTenantId === ID) {
          this.showModal = false;
          // If the selected tenant ID matches the existing tenant ID, show the confirmation message
          var lastView = localStorage.getItem("lastView");
          if (lastView) {
            this.showAlert = true;
          } else {
            this.$router.push("/data-management");
          }
        } else {
          localStorage.removeItem("activeMenu")
          // If the selected tenant ID does not match the existing tenant ID, navigate to /assets
          this.$router.push("/data-management");
        }
      }
    },

    goToLastView() {
      var lastView = localStorage.getItem("lastView");
      this.showAlert = false;
      if (lastView) {
        this.$router.push(lastView);
      } else {
        this.$router.push("/data-management");
      }
    },
    goToAssets() {
      this.$router.push("/data-management");
      localStorage.removeItem("activeMenu")
    },

    required(v) {
      return !!v || "Field is required";
    },
    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    closeModal() {
      this.showModal = false;
      localStorage.removeItem("authToken");
    },
  },
};
</script>