<template>
  <div class="dash-page-wrapper">
    <DashboardList ref="reloadDashboards" @selected-dashboard="handleselectedDashboard" />
    <DashboardView :selectedDashboard="selectedDashboard" @dashboard-delete="reloadDashboards"/>
  </div>
  </template>
        
    <script>
  import DashboardList from '@/components/layout/DashboardNew/DashboardList.vue';
  import DashboardView from '@/components/layout/DashboardNew/DashboardView.vue';
  export default {
    components: { DashboardList, DashboardView },
    name: "List",
    data() {
      return {
        selectedDashboard: {},
      }
    },
    methods: {
      handleselectedDashboard (dashboard) {
this.selectedDashboard = dashboard
      },
      reloadDashboards() {
        this.selectedDashboard = {};
      this.$refs.reloadDashboards.getSavedDashboards();
    },
    },
  };
  </script>