<template>
    <DashboardView />
  </template>
        
    <script>
  import DashboardView from '@/components/layout/DashboardNew/DashboardView.vue';
  export default {
    components: { DashboardView },
    name: "View",
  };
  </script>