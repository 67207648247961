<template>
  <div class="dash-top">
    <div class="dash-top-left" @click="dashBack">
      <img :src="back" alt="back" />
      <span>Back to List</span>
    </div>
    <div class="dash-top-center">
      <!-- <img :src="star" alt="star" /> -->
      <img
        :src="isDefault ? filledStar : star"
        alt="star"
        @click="toggleStar"
      />
      <input
        type="text"
        v-model="name"
        placeholder="untitled"
        @change="dashboardNameChange(name)"
      />
      <img :src="pencil" alt="pencil" />
    </div>
    <div class="dash-top-right">
      <!-- Toggle Switch -->
      <div class="tab-div dash-access-toggle justify-space-between">
        <v-btn-toggle
          v-model="isPublic"
          class="axis-toggle font-overlay"
          mandatory
          @update:modelValue="handleDashAccessToggleChange"
        >
          <v-btn :value="false" class="switch-btn" outlined>Private</v-btn>
          <v-btn :value="true" class="switch-btn" outlined>Public</v-btn>
        </v-btn-toggle>
      </div>

      <!-- Separate Delete Button -->
      <button class="dash-delete" @click="handleDashDelete">
        <img :src="delete" alt="delete" />
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "DashboardCreateTop",
  data() {
    return {
      back: require("../../../assets/Svg/Dashboard/back.svg"),
      star: require("../../../assets/Svg/Dashboard/star-empty.svg"),
      filledStar: require("../../../assets/Svg/Dashboard/star-filled.svg"),
      pencil: require("../../../assets/Svg/Dashboard/pencil.svg"),
      delete: require("../../../assets/Svg/Dashboard/delete.svg"),
      name: "",
      accessLevel: "",
      isPublic: false,
      isDefault: false,
      dashboard_id: "",
    };
  },
  props: {
    DashName: {
      type: String,
      default: "untitled",
    },
    DashAccessLevel: {
      type: String,
      default: "private",
    },
    DashisDefault: {
      type: Boolean,
      default: false,
    },
    dashboard_id: {
      type: String,
    },
  },
  mounted() {
    this.name = this.DashName;
    this.accessLevel = this.DashAccessLevel;
    this.isPublic = this.DashAccessLevel === "public";
    this.isDefault = this.DashisDefault;
  },
  watch: {
    DashName: function(newVal) {
      this.name = newVal;
    },
    DashAccessLevel: function(newVal) {
      this.accessLevel = newVal;
      this.isPublic = newVal === "public";
    },
    DashisDefault: function(newVal) {
      this.isDefault = newVal;
    },
    dashboard_id: function(newVal) {
      this.dashboard_id = newVal;
    },
  },
  methods: {
    toggleStar() {
      this.isDefault = !this.isDefault;
      this.$emit("dashboard-default", this.isDefault);
    },
    dashboardNameChange(value) {
      this.name = value;
      this.$emit("dashboard-name", this.name);
    },
    handleDashAccessToggleChange() {
      // Update access level based on the switch state
      this.accessLevel = this.isPublic ? "public" : "private";
      this.$emit("dashboard-access-level", this.accessLevel);
    },
    handleDashDelete() {
      this.$emit("handleDashDelete");
    },
    resetDashboardName() {
      this.name = "untitled";
      this.accessLevel = "private";
      this.isPublic = false;
      this.isDefault = false;
    },
    dashBack() {
      this.$router.push("/Dashboards");
    },
  },
};
</script>