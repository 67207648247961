<template>
  <div>
    <DashboardCreateTop
    ref="dashboardTop"
    :DashAccessLevel="accessLevel" :DashisDefault="isDefault" 
      :DashName="name"
      :dashboard_id="dashboardId"
      @dashboard-name="handleDashboardName"
      @dashboard-access-level="handleAccessLevel"
      @handleDashDelete="handleDashDelete"
      @dashboard-default="handleDefaultDashboard"
    />
    <div class="dash-page-wrapper">
      <ChartList />
      <DashboardCreate  ref="resetDash"  :name="name" :accessLevel="accessLevel" :isDefault="isDefault" @dashboard-id="handleDashID" />
    </div>
    <DeleteDashboard ref="deleteDashboard" :dashboard_id="dashboardId" @dashboard-delete="handleDashDeleteCreate" />
  </div>
</template>

<script>
import DashboardCreateTop from "@/components/layout/DashboardNew/DashboardCreateTop.vue";
import ChartList from "@/components/layout/DashboardNew/ChartList.vue";
import DashboardCreate from "@/components/layout/DashboardNew/DashboardCreate.vue";
import DeleteDashboard from "@/components/layout/DashboardNew/DeleteDashboard.vue";
export default {
  components: { ChartList, DashboardCreateTop, DashboardCreate, DeleteDashboard },
  name: "List",
  data() {
    return {
      name: "untitled",
      accessLevel: "private",
      isDefault: false,
      dashboardId: '', // Assuming dashboardId is available or can be fetched
    };
  },
  methods: {
    handleDashboardName(name) {
      this.name = name;
    },
    handleDashID(dashboard){
      this.dashboardId = dashboard.ID;
      this.name = dashboard.Name;
    },
    handleDashDelete(){
      this.$refs.deleteDashboard.dashboardDelete();
    },
    handleDashDeleteCreate(){
      this.$refs.resetDash.resetDashboardState();
      this.$refs.dashboardTop.resetDashboardName();
    },
    handleAccessLevel(accessLevel) {
      this.accessLevel = accessLevel;
    },
    handleDefaultDashboard(isDefault) {
      this.isDefault = isDefault;
    },
  },
};
</script>