<template>
  <div class="chart-wrap">
    <v-row no-gutters>
      <v-col v-for="(chart, index) in Array(6).fill(null)" :key="index" cols="12" md="6" class="pr-4 pb-4 chart-cols">
        <!-- <div class="chart-column dashboard-chart-column mb-0"> -->
        <div v-if="showSelectboxes" class="edit-dashboard-selectbox">
          <!-- <label for="">Select Saved Chart</label> -->
          <v-select variant="solo" class="custom-field" clearable v-model="chartSelections[index]" item-value="Name"
            item-text="Name" placeholder="Select Plot" :items="chartTypesName"
            @update:modelValue="(value) => $emit('chart-type-changed', value, index)"></v-select>
        </div>
        <div :class="[
          'dashboard-chart-column mb-0',
          {
            'chart-column': hideExpand ? Array(6).fill(null) : index < chartTypes.length,
            'selectbox-present': showSelectboxes
          }
        ]">

          <!-- Show chart if it exists -->
          <div v-if="Array(6).fill(null)">
            <div v-if="loading[index]">
              <DashboardCharts />
            </div>
            <div v-else>
              <div v-if="timeoutMessage[index]" class="main-chart timeout">
                <div class="text-center">
                  {{ timeoutMessage[index] }}
                  <v-icon flat @click="fetchChartsData(index)" color="blue-darken-3" icon="mdi-reload"
                    size="large"></v-icon>
                </div>
              </div>
              <div v-else-if="apiError[index]" class="main-chart timeout">
                <div class="text-center" v-html="apiError[index]"></div>
              </div>
              <div v-else-if="noData[index]" class="tabs-no-test-div">
                <h6 v-html="noData[index]"></h6>
              </div>
              <div v-else>
                <div v-if="chartSelections[index] || (chartTypes.length > index && chartTypes[index]?.Name)" class="chart-name-top">
                  <h6>{{ chartSelections[index] || chartTypes[index]?.Name }}</h6>
                </div> 
                <div :ref="'chart_' + index" class="main-chart">
                </div>
              </div>
            </div>

            <v-dialog v-model="isPreviewOpen[index]"
              class="multi-chart-preview-div dashboard-chart-preview composition">
              <v-card class="position-relative studio-card dashboard-card">
                <template
                  v-if="chartTypes[index]?.ChartType === 'Scatter Plot' || chartTypes[index]?.ChartType === 'Line Plot'">
                  <!-- <div class="modal-switch-div">
                    <div class="switch-div">
                      <label for="">X Axis Scale:</label>
                      <v-switch v-model="isModalXLog" color="info" inset :label="xAxisScaleModal" @change="handleModalXScaleToggleChange"
                        hide-details></v-switch>
                    </div>
                    <div class="switch-div">
                      <label for="">Y Axis Scale:</label>
                      <v-switch v-model="isModalYLog" color="info" inset :label="yAxisScaleModal" @change="handleModalYScaleToggleChange"
                        hide-details></v-switch>
                    </div>
                  </div> -->
                  <div class="modal-tab-div">
                    <div class="tab-div justify-space-between">
                      <p>X Axis Scale</p>
                      <v-btn-toggle v-model="isModalXLog" class="axis-toggle" mandatory
                        @update:modelValue="handleModalXScaleToggleChange">
                        <v-btn :value="true" class="switch-btn" outlined>Log</v-btn>
                        <v-btn :value="false" class="switch-btn" outlined>Linear</v-btn>
                      </v-btn-toggle>
                    </div>
                    <div class="tab-div  justify-space-between">
                      <p>Y Axis Scale</p>
                      <v-btn-toggle v-model="isModalYLog" class="axis-toggle" mandatory
                        @update:modelValue="handleModalYScaleToggleChange">
                        <v-btn class="switch-btn" :value="true" outlined>Log</v-btn>
                        <v-btn class="switch-btn" :value="false" outlined>Linear</v-btn>
                      </v-btn-toggle>
                    </div>
                  </div>
                </template>
                <v-card-text>
                  <div class="popup-top">
                    <div class="chart-name-top w-100">
                      <h6>{{ chartTypes[index].Name }}</h6>
                    </div>
                    <!-- <img :src="cross" alt="cross" @click="closeModal(index)" class="chart-preview-close-btn"> -->
                  </div>
                  <div :ref="'modal_chart_' + index" class="modal-chart-container"></div>
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </v-col>
    </v-row>

    <div v-if="chartTypes.length === 0" class="tabs-no-test-div">
      <h6>Chart Types are not available.</h6>
    </div>
  </div>
</template>

<script>
import api from "../../../axiosInterceptor";
import Plotly from "plotly.js-dist-min";
import DashboardCharts from "../Loaders/DashboardCharts.vue";
import axios from "axios";

import downloadIcon from '../../../assets/Svg/Chart/csv_chart.svg';
import Expand from "../../../assets/Svg/Chart/expand-btn.svg";
import { getDisplayName } from "../../../utils/cache";

export default {
  name: "ChartsData",
  data() {
    return {
      cross: require("../../../assets/Svg/Chart/chart-cross.svg"),
      tableData: [],
      loading: this.chartTypes.map(() => true),
      isPreviewOpen: this.chartTypes.map(() => false),
      dataLoaded: this.chartTypes.map(() => false),
      timeoutMessage: this.chartTypes.map(() => ""),
      apiError: this.chartTypes.map(() => ""),
      selectedColorGroupKeys: this.chartTypes.map(() => ""),
      selectedYAxisKeys: this.chartTypes.map(() => []),
      selectedXAxisKeys: this.chartTypes.map(() => []),
      selectedZAxisKeys: this.chartTypes.map(() => []),
      xBins: this.chartTypes.map(() => []),
      yBins: this.chartTypes.map(() => []),
      // xAxisCaption: this.chartTypes.map(() => []),
      // yAxisCaption: this.chartTypes.map(() => []),
      markerSizes: [],
      noData: this.chartTypes.map(() => ""),
      // chartSelections: Array(6).fill(null),
      // chartTypesNames: [],
      selectedChartIds: [],
      showStudioDialog: false,
      updatedXColumnDisplayName: this.chartTypes.map(() => []),
      updatedYColumnDisplayName: this.chartTypes.map(() => []),
      xAxisScaleModal: "linear",
      yAxisScaleModal: "linear",
      isModalXLog: false,
      isModalYLog: false,
      expandedIndex: null
    };
  },
  components: {
    DashboardCharts,
  },
  props: {
    chartTypes: {
      type: Array,
      required: true,
    },
    showSelectboxes: {
      type: Boolean,
      required: true,
    },
    chartSelections: {
      type: Array,
      required: true,
    },
    chartTypesName: {
      type: Array,
      required: true,
    },
    hideExpand: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    xAxisScaleModal: "plotModalChart",
    yAxisScaleModal: "plotModalChart",
    showSelectboxes() {
      // this.fetchData();
    },
  },
  methods: {
    handleModalXScaleToggleChange() {
      this.xAxisScaleModal = this.isModalXLog ? "log" : "linear";
    },
    handleModalYScaleToggleChange() {
      this.yAxisScaleModal = this.isModalYLog ? "log" : "linear";
    },
    updateSelectedChartIds(chartId) {
      const index = this.selectedChartIds.indexOf(chartId);
      if (index === -1) {
        this.selectedChartIds.push(chartId);
      } else {
        this.selectedChartIds.splice(index, 1);
      }
    },
    openStudioDataDialog() {
      this.showStudioDialog = true;
      this.$emit("update-show-studio-dialog", this.showStudioDialog);
    },
    clearChart(index) {
      if (index !== undefined) {
        this.chartTypes[index] = null; // Clear the chart type
        this.tableData[index] = null; // Clear table data
        this.dataLoaded[index] = false; // Reset loaded state
        this.loading[index] = false; // Stop loader
        this.noData[index] = null; // Reset no data message
        this.renderChart(null, index, "chart_"); // Clear the rendered chart
      }
    },
    async fetchChartsData(index = null, selectedChart) {
      if (index !== null) {
        this.clearMessages(index);
        this.loading[index] = true;

        try {
          if (selectedChart) {
            var chart = selectedChart;
          }
          else {
            var chart = this.chartTypes[index];
          }
          const response = await api.get(`public/api/v1/charts/data/${chart.ID}`);

          if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
            const rows = response.data.data.TableData.rows;
            if (rows) {
              this.tableData[index] = rows.map(row => {
                Object.keys(row).forEach(key => {
                  if (key + "_decimal" in row && row[key + "_decimal"] !== null) {
                    row[key] = parseFloat(row[key]).toFixed(row[key + "_decimal"]);
                  }
                });
                return row;
              });
              this.dataLoaded[index] = true;
              this.loading[index] = false;
              this.renderChart(chart, index, "chart_");
            }
            else {
              this.loading[index] = false;
              this.noData[i] = "no data available to plot chart."
            }
          }
          else if (response && response.data && response.data.message) {
            this.handleError(index, response.data.message);
          }
          else {
            this.handleError(index, "Error in fetching charts data");
          }
        } catch (error) {
          this.handleError(index, error);
        }
      } else {
        this.clearAllMessages();
        this.loading = this.chartTypes.map(() => true);

        for (let i = 0; i < this.chartTypes.length; i++) {
          const chart = this.chartTypes[i];
          try {
            const response = await api.get(`public/api/v1/charts/data/${chart.ID}`);

            if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
              const rows = response.data.data.TableData.rows;
              if (rows) {
                this.tableData[i] = rows.map(row => {
                  Object.keys(row).forEach(key => {
                    if (key + "_decimal" in row && row[key + "_decimal"] !== null) {
                      row[key] = parseFloat(row[key]).toFixed(row[key + "_decimal"]);
                    }
                  });
                  // this.xAxisCaption[index] = row.caption
                  // this.yAxisCaption[index] = row.caption
                  return row;
                });
                this.dataLoaded[i] = true;
                this.loading[i] = false;
                const xAxisColumn = chart.XAxisColumn;
                const capitalizedXAxisColumn = xAxisColumn.charAt(0).toUpperCase() + xAxisColumn.slice(1); // Capitalize the first letter
                var xColumnDisplayName = await getDisplayName(chart.TableType, capitalizedXAxisColumn);
                this.updatedXColumnDisplayName[i] = xColumnDisplayName.displayName;
                const yAxisColumns = chart.YAxisColumn ? chart.YAxisColumn.split(",") : [];
                if (chart.GroupColumn && chart.YAxisColumn) {
                  yAxisColumns.pop(); // Remove the last Y-axis column if GroupColumn is present
                }
                // Capitalize each column in yAxisColumns
                const capitalizedYAxisColumns = yAxisColumns.map(column =>
                  column.charAt(0).toUpperCase() + column.slice(1)
                );
                var yColumnDisplayNames = await Promise.all(
                  capitalizedYAxisColumns.map(column => getDisplayName(chart.TableType, column))
                );
                this.updatedYColumnDisplayName[i] = yColumnDisplayNames.map(item => item.displayName).join(', ');
                this.renderChart(chart, i, "chart_");
              }
              else {
                this.loading[i] = false;
                this.noData[i] = "no data available to plot chart."
              }
            }
            else if (response && response.data && response.data.message) {
              this.handleError(i, response.data.message);
            }
            else {
              this.handleError(i, "Error in fetching charts data");
            }
          } catch (error) {
            this.handleError(i, error);
          }
        }
      }
    },

    openModal(index) {
      this.xAxisScaleModal = this.chartTypes[index].XAxisScale;
      this.yAxisScaleModal = this.chartTypes[index].YAxisScale;
      if (this.xAxisScaleModal === "log") {
        this.isModalXLog = true;
      }
      else {
        this.isModalXLog = false;
      }
      if (this.yAxisScaleModal === "log") {
        this.isModalYLog = true;
      }
      else {
        this.isModalYLog = false;
      }
      // this.isModalXLog = true;
      // this.isModalYLog = true;
      this.expandedIndex = index;
      this.isPreviewOpen[index] = true;
      this.resizeChart(index, "modal_chart_");
      this.$nextTick(() => {
        // Delay resize to ensure the modal is fully rendered
        this.renderChart(this.chartTypes[index], index, "modal_chart_");
        // Adjust based on your modal's animation duration
      });
    },

    closeModal(index) {
      this.isPreviewOpen[index] = false;
    },

    resizeChart(index, refPrefix) {
      this.$nextTick(() => {
        const chartRef = this.$refs[refPrefix + index];

        if (!chartRef) {
          console.warn(`Chart reference for ${refPrefix + index} is undefined.`);
          return;
        }

        const chartDiv = Array.isArray(chartRef) ? chartRef[0] : chartRef;
        if (!chartDiv) return;

        Plotly.Plots.resize(chartDiv); // Resize the chart
      });
    },
    plotModalChart() {
      const modalChartRef = this.$refs['modal_chart_' + this.expandedIndex];
      if (!modalChartRef) return;
      const chartDiv = Array.isArray(modalChartRef) ? modalChartRef[0] : modalChartRef;
      const traces = this.generateTraces(this.chartTypes[this.expandedIndex], this.expandedIndex);
      const layout = this.generateLayout(this.chartTypes[this.expandedIndex], this.expandedIndex);
      const config = this.generateConfig(this.expandedIndex);

      this.$nextTick(() => {
        if (this.chartTypes[this.expandedIndex]?.ChartType === "Scatter Plot" || this.chartTypes[this.expandedIndex]?.ChartType === "Line Plot") {
          layout.xaxis.type = this.xAxisScaleModal;
          layout.yaxis.type = this.yAxisScaleModal;
        }
        Plotly.newPlot(chartDiv, traces, layout, config);
      });
    },

    renderChart(chart, index, refPrefix) {
      this.$nextTick(() => {
        const chartRef = this.$refs[refPrefix + index];
        if (!chartRef) {
          console.warn(`Chart reference for ${refPrefix + index} is undefined.`);
          return;
        }

        const chartDiv = Array.isArray(chartRef) ? chartRef[0] : chartRef;
        if (!chartDiv) return;

        const traces = this.generateTraces(chart, index);
        const layout = this.generateLayout(chart, index);
        const config = this.generateConfig(index);
        if (chart.ChartType === "Scatter Plot" || chart.ChartType === "Line Plot") {
          layout.xaxis.type = chart?.XAxisScale || "linear";
          layout.yaxis.type = chart?.YAxisScale || "linear";
        }
        Plotly.newPlot(chartDiv, traces, layout, config);
      });
    },

    generateTraces(chart, index) {
      if (!chart) {
        console.warn("generateTraces received a null or undefined chart:", chart);
        return; // Exit early if chart is invalid
      }

      // Validate required properties like ChartType
      if (!chart.ChartType) {
        console.error("Chart object is missing required property 'ChartType':", chart);
        return;
      }
      const traceType = this.getTraceType(chart);
      const traceMode = this.getTraceMode(chart);
      const scatterMarkerSizes = 8;

      // const yColumns = chart.YAxisColumn.split(",");
      const yColumns = chart.YAxisColumn ? chart.YAxisColumn.split(",") : [];
      this.selectedYAxisKeys[index] = yColumns;

      if (chart.GroupColumn && chart.YAxisColumn) {
        yColumns.pop();
        this.selectedColorGroupKeys[index] = chart.GroupColumn;
        this.selectedXAxisKeys[index] = chart.XAxisColumn;
        this.selectedZAxisKeys[index] = chart.ZAxisColumn;
        this.xBins[index] = chart.NumXBins;
        this.yBins[index] = chart.NumYBins;

        const colorGroupData = this.tableData[index].map(item => item[chart.GroupColumn]);
        const uniqueValues = [...new Set(colorGroupData.filter(item => item !== undefined && item !== null))];

        const groupedYAxisData = {};
        const groupedXAxisData = {};
        const groupedZAxisData = {};

        uniqueValues.forEach(value => {
          groupedYAxisData[value] = [];
          groupedXAxisData[value] = [];
          groupedZAxisData[value] = [];
        });

        this.tableData[index].forEach(row => {
          const colorGroupValue = row[this.selectedColorGroupKeys[index]];

          if (uniqueValues.includes(colorGroupValue)) {
            const yAxisRowData = this.selectedYAxisKeys[index].map(key => row[key]);
            groupedYAxisData[colorGroupValue].push(yAxisRowData);

            const xAxisRowData = row[this.selectedXAxisKeys[index]];
            groupedXAxisData[colorGroupValue].push(xAxisRowData);

            const zAxisRowData = row[this.selectedZAxisKeys[index]];
            groupedZAxisData[colorGroupValue].push(zAxisRowData);
          }
        });

        return Object.keys(groupedXAxisData).map((key) => {
          const xData = groupedXAxisData[key];
          let yData = groupedYAxisData[key];
          const zData = groupedZAxisData[key];
          this.markerSizes = [...zData];

          if (!Array.isArray(xData) || !Array.isArray(yData) || xData.length !== yData.length) {
            return null;
          }

          yData = yData.map(item => item[0]);

          const markerSize = (chart.ChartType === "Scatter Plot")
            ? (scatterMarkerSizes)
            : (this.markerSizes);

          const trace = {
            x: xData,
            y: yData,
            z: zData,
            type: traceType,
            mode: traceMode,
            name: key,
            marker: {
              size: markerSize
            },
          };

          if (chart.ChartType === "Bubble" || chart.ChartType === "3D Scatter") {
            const maxMarkerSize = Math.max(...this.markerSizes);
            trace.marker = {
              ...trace.marker,
              sizemode: 'area',
              sizeref: 2.0 * maxMarkerSize / (80 * 2), // Adjusted size reference for better fitting
              sizemin: 2,
            };
          }

          if (["2D Histogram"].includes(chart.ChartType)) {
            trace.autobinx = false;
            trace.autobiny = false;
            trace.xbins = { size: this.calculateBinSize(xData, this.xBins[index]) };
            trace.ybins = { size: this.calculateBinSize(yData, this.yBins[index]) };
          }

          return trace;
        }).filter(trace => trace !== null);
      } else {
        const xData = this.tableData[index].map(item => item[chart.XAxisColumn]);
        this.xBins[index] = chart.NumXBins;
        if (chart.ChartType === "Histogram") {
          //  handling for histogram: Only x-axis is needed
          // Create the histogram trace
          const trace = {
            x: xData,
            type: traceType,
            mode: traceMode,
            autobinx: false,
            xbins: {
              size: this.calculateBinSize(xData, this.xBins[index]), // Calculate bin size for the histogram
            },
          };

          return [trace];
        }
        const yData = yColumns.map(col => this.tableData[index].map(item => item[col]));
        this.yBins[index] = chart.NumYBins;
        const zData = this.tableData[index].map(item => item[chart.ZAxisColumn]);

        this.markerSizes = [...zData];

        const markerSize = (chart.ChartType === "Scatter Plot")
          ? (scatterMarkerSizes)
          : (this.markerSizes);

        return yData.map((y, i) => {
          const trace = {
            x: xData,
            y: y,
            z: zData,
            type: traceType,
            mode: traceMode,
            name: yColumns[i],
            marker: {
              size: markerSize
            },
            //         customdata: xData && xData.length > 0
            //   ? xData.map((value) => {
            //       const idx = this.tableData[index].findIndex(item => item[chart.XAxisColumn] === value);
            //       return this.xAxisCaption[idx] || "N/A"; // Use the caption corresponding to the idx
            //   })
            //   : yData.map((yValue, i) => {
            //       const yIndex = this.tableData[index].findIndex(item => item[col] === yValue);
            //       return this.yAxisCaption[yIndex] || "N/A";
            //   }),

            // hovertemplate: `
            //   %{x}  %{y}  %{customdata}
            //   <extra></extra>
            // `,
          };

          if (chart.ChartType === "Bubble" || chart.ChartType === "3D Scatter") {
            const maxMarkerSize = Math.max(...this.markerSizes);
            trace.marker = {
              ...trace.marker,
              sizemode: 'area',
              sizeref: 2.0 * maxMarkerSize / (80 * 2), // Adjusted size reference for better fitting
              sizemin: 2,
            };
          }

          if (chart.ChartType === "2D Histogram") {
            trace.colorscale = [
              [0.0, 'rgba(247,251,255,255)'],
              [0.25, 'rgba(193,217,237,255)'],
              [0.5, 'rgba(97,167,210,255)'],
              [0.75, 'rgba(24,101,172,255)'],
              [1.0, 'rgba(8,48,108,255)']
            ];
            trace.colorbar = {
              title: {
                text: 'Number of Samples', // Title for the color bar
                side: 'right',
              }
            };
          }

          if (["2D Histogram"].includes(chart.ChartType)) {
            trace.autobinx = false;
            trace.autobiny = false;
            trace.xbins = { size: this.calculateBinSize(xData, this.xBins[index]) };
            trace.ybins = { size: this.calculateBinSize(y, this.yBins[index]) };
          }

          return trace;
        });
      }
    },

    calculateBinSize(data, bins) {
      const numericData = data.filter(value => typeof value === 'number' && !isNaN(value));
      if (numericData.length === 0) return 1;
      const minValue = Math.min(...numericData);
      const maxValue = Math.max(...numericData);
      return (maxValue - minValue) / bins;
    },

    generateLayout(chart, index) {
      if (!chart) {
        console.warn("generateLayout received a null or undefined chart:", chart);
        return; // Exit early if chart is invalid
      }

      // Validate required properties like ChartType
      if (!chart.ChartType) {
        console.error("Chart object is missing required property 'ChartType':", chart);
        return;
      }
      // const yColumns = chart.YAxisColumn.split(",");
      const yColumns = chart.YAxisColumn ? chart.YAxisColumn.split(",") : [];
      if (chart.GroupColumn && chart.YAxisColumn) {
        yColumns.pop(); // Remove the last Y-axis column if GroupColumn is present
      }
      return {
        // title: `${chart.Name} <br> (${chart.ChartType})`,
        xaxis: { title: this.updatedXColumnDisplayName[index] || chart.XAxisColumn },
        yaxis: {
          title: chart.ChartType === "Histogram" ? "Number of Samples" : this.updatedYColumnDisplayName[index]?.replace(/, /g, "<br>") || yColumns.join(", ")?.replace(/, /g, "<br>"),
          automargin: true,
        },
        margin: {
          l: 50,
          r: 0
        },
        legend: {
          title: {
            text: this.selectedColorGroupKeys[index] ? this.selectedColorGroupKeys[index] : ''
          }
        },
        // updatemenus: [
        //   {
        //     buttons: [
        //       {
        //         label: "Download CSV",
        //         method: "downloadCsv",
        //       },
        //     ],
        //   },
        // ],
      };
    },

    generateConfig(index) {
      return {
        responsive: true,
        displaylogo: false,
        displayModeBar: true,
        modeBarButtonsToRemove: ["lasso2d", "select2d", "pan2d", "resetscale", "zoom"],
        modeBarButtonsToAdd: [
          {
            name: "Expand",
            icon: {
              svg: `
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <image href="${Expand}" width="24" height="24"/>
                </svg>
              `,
            },
            click: this.openModal.bind(this, index),
          },
          {
            name: 'Download CSV',
            icon: {
              svg: `
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <image href="${downloadIcon}" width="24" height="24"/>
                </svg>
              `,
            },
            click: this.handleDownloadCSV.bind(this, index)
          },
        ]
      };
    },

    getTraceType(chart) {
      if (!chart || !chart.ChartType) {
        console.warn("Invalid chart object passed to getTraceType:", chart);
        return null; // Return a fallback or handle gracefully
      }
      switch (chart.ChartType) {
        case "Line Plot": return "scatter";
        case "Bar Chart": return "bar";
        case "Histogram": return "histogram";
        case "Bubble": return "scatter";
        case "3D Scatter": return "scatter3d";
        case "Scatter Plot": return "scatter";
        case "2D Histogram": return "histogram2d";
        case "Heatmap": return "heatmap";
        case "Treemap": return "treemap";
        default: return "scatter";
      }
    },

    getTraceMode(chart) {
      if (chart.ChartType === "Bubble") return "markers";
      if (chart.ChartType === "Scatter Plot") return "markers";
      return undefined;
    },

    handleDownloadCSV(index) {
      const chartData = this.tableData[index];
      const csvContent = this.convertToCsv(chartData);
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const fileName = `chart_data_${index}.csv`;

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    convertToCsv(data) {
      const csvRows = [];
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(","));

      for (const row of data) {
        const values = headers.map(header => JSON.stringify(row[header], (key, value) => value === null ? "" : value));
        csvRows.push(values.join(","));
      }

      return csvRows.join("\n");
    },

    clearMessages(index) {
      this.timeoutMessage[index] = "";
      this.apiError[index] = "";
    },

    clearAllMessages() {
      this.timeoutMessage = this.chartTypes.map(() => "");
      this.apiError = this.chartTypes.map(() => "");
    },

    handleError(index, error) {
      this.loading[index] = false;
      this.timeoutMessage[index] = error.message || "An error occurred";
      if (error.response && error.response.status) {
        this.apiError[index] = "Api Error";
      }
    },
  },

  mounted() {
    window.addEventListener('storage', this.handleStorageChange);
    this.fetchChartsData();
  },
};
</script>